import React from 'react';
import { CiberseguridadProvisionerPage } from 'fractalia-provisioner/ciberseguridad';
import { CiberalarmaProvisionerPage } from 'fractalia-provisioner/ciberalarma';
import { LegacyProvisionerPage } from 'fractalia-provisioner/legacy';
import { LoginPage } from 'fractalia-provisioner/identity';
import { SmartsupportProvisionerPage } from 'fractalia-provisioner/smartsupport';
import WelcomePage from './pages/WelcomePage';

const routes = [{
        path: '/',
        component: WelcomePage,
        secured: true,
        exact: true,
    },
    {
        path: '/smartsupport',
        component: SmartsupportProvisionerPage,
        secured: true,
        exact: false,
    },
    {
        path: '/legacy',
        component: LegacyProvisionerPage,
        secured: true,
        exact: false,
    },
    {
        path: '/ciberseguridad',
        component: CiberseguridadProvisionerPage,
        secured: true,
        exact: false,
    },
    {
        path: '/ciberalarma',
        component: CiberalarmaProvisionerPage,
        secured: true,
        exact: false,
    },
    {
        path: '/identity/login',
        component: LoginPage,
        secured: false,
        exact: false,
    },
];

export default routes;