import React from 'react';
import styled from '@emotion/styled';
import { Table } from 'fractalia-design';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const columns = {
  title: 'Fecha',
  dataIndex: 'createDate',
  key: 'createDate',
};

function ProvisionHistory() {
  return (
    <Wrapper>
      <Table></Table>
    </Wrapper>
  );
}

export default ProvisionHistory;
