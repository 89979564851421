import { all, call, put, takeLatest } from 'redux-saga/effects';

import CiberseguridadAPI from '../api/CiberseguridadAPI';
import {
    ciberseguridadProvisioningSubmitFailedAction,
    ciberseguridadProvisioningSubmitRequestedAction,
    ciberseguridadProvisioningSubmitSucceededAction,
    ciberseguridadHistoryFetchFailedAction,
    ciberseguridadHistoryFetchRequestedAction,
    ciberseguridadHistoryFetchSucceededAction,
} from './actions';
import { CIBERSEGURIDAD_PROVISIONING_SUBMIT, CIBERSEGURIDAD_HISTORY_FETCH } from './types';

const provisioningSubmitAsync = async(data, provisioningSourceFile) =>
    // eslint-disable-next-line no-return-await
    await CiberseguridadAPI.submitProvisioning(data, provisioningSourceFile)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

const historyFetchAsync = async(applicationUserId) =>
    // eslint-disable-next-line no-return-await
    await CiberseguridadAPI.historyFetch(applicationUserId)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* provisioningSubmitSaga(action) {
    try {
        const { data, provisioningSourceFile } = action;
        // requested
        yield put(ciberseguridadProvisioningSubmitRequestedAction());
        yield call(provisioningSubmitAsync, data, provisioningSourceFile);
        // succeeded
        yield put(ciberseguridadProvisioningSubmitSucceededAction());
    } catch (error) {
        // error
        yield put(ciberseguridadProvisioningSubmitFailedAction(error));
    }
}

function* historyFetchSaga(action) {
    try {
        // requested
        yield put(ciberseguridadHistoryFetchRequestedAction());
        const { applicationUserId } = action;
        const data = yield call(historyFetchAsync, applicationUserId);
        // succeeded
        yield put(ciberseguridadHistoryFetchSucceededAction(data));
    } catch (error) {
        // error
        yield put(ciberseguridadHistoryFetchFailedAction(error));
    }
}

export default function* rootSaga() {
    yield all([takeLatest(CIBERSEGURIDAD_PROVISIONING_SUBMIT, provisioningSubmitSaga), takeLatest(CIBERSEGURIDAD_HISTORY_FETCH, historyFetchSaga)]);
}