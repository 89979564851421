import React from 'react';
import { Menu as MenuBase } from 'fractalia-design';
import styled from '@emotion/styled';

const Menu = styled(MenuBase)`
  align-self: center;
  margin-bottom: 8px;

  .ant-menu-item:not(:nth-child(2)) {
    min-width: 200px;
    text-align: center;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0px;
  }
`;

Menu.Item = MenuBase.Item;

export default Menu;
