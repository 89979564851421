import App from './app';
import ApplicationContext from './app/context';
import React from 'react';
import ReactDOM from 'react-dom';
import { Healthy } from 'react-healthy';

const services = [
  {
    name: 'api',
    endpoint: 'https://api.provision.devonlineassist.me/identity-service/',
    message:
      'Hola, actualmente estamos experimentando problemas con el servidor, pronto estaremos solucionando el inconveniente.',
  },
];

ReactDOM.render(
  <ApplicationContext>
    <App />
  </ApplicationContext>,
  document.getElementById('root')
);

window.noop = () => {};
