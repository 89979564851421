/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */

import { Icons, Upload, notification } from 'fractalia-design';
import React, { useState } from 'react';

import XLSX from 'xlsx';
import styled from '@emotion/styled';
import { useEffect } from 'react';

const { Dragger } = Upload;
const { InboxOutlined } = Icons;

//========================================================================================
/*                                                                                      *
 *                                        Wrapper                                       *
 *                                                                                      */
//========================================================================================

const Wrapper = styled.div`
  padding: 20px 50px 0px;
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .dragger {
    min-width: 600px;
    display: flex;
    justify-content: center;

    .icon {
      font-size: 2rem;
      color: #08c;
    }

    .text {
      font-size: 1.2rem;
    }
  }
`;

//========================================================================================
/*                                                                                      *
 *                                      UsersLoader                                     *
 *                                                                                      */
//========================================================================================

function UsersLoader({ onLoad, columns }) {
  //* ────────────────────────────────────────────────────────────────────────────────
  //* ─── STATE ──────────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  const [fileList, setFileList] = useState([]);

  //* ────────────────────────────────────────────────────────────────────────────────
  //* ─── VARIABLES ──────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  const accept = '.xls, .xlsx';

  //* ────────────────────────────────────────────────────────────────────────────────
  //* ─── EFFECTS ────────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xlsxFile = e.target.result;
        if (!xlsxFile) {
          console.log('error');
          return;
        }
        handleXlsxFile(xlsxFile);
      };
      reader.readAsArrayBuffer(fileList[0]);
    }
  }, [fileList]);

  //* ────────────────────────────────────────────────────────────────────────────────
  //* ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  function beforeUpload(file) {
    setFileList([file]);
    return false;
  }

  function checkHeaders(sheet) {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const R = range.s.r;

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
      const hdr =
        cell && cell.t ? XLSX.utils.format_cell(cell) : `UNKNOWN ${C}`;
      headers.push(hdr);
    }

    const requiredHeaders = columns
      .filter((col) => col.required)
      .map((col) => col.header.replace(/\s+/g, ''));

    let invalidHeaders = false;

    requiredHeaders.forEach((requiredHeader) => {
      if (
        !headers.some(
          (header) =>
            header
              .replace(/\s+/g, '')
              .localeCompare(requiredHeader, undefined, {
                sensitivity: 'base',
              }) === 0
        )
      ) {
        notification.error({
          message: `No se encontro la columna ${requiredHeader}`,
        });
        invalidHeaders = true;
      }
    });
    if (invalidHeaders) {
      return null;
    }
    return headers;
  }

  function handleXlsxFile(xlsxFile) {
    // reading xlsx file
    const workbook = XLSX.read(new Uint8Array(xlsxFile), {
      type: 'array',
      raw: false,
      cellDates: true,
    });

    if (!workbook) {
      console.log('No se pudo leer el libro.');
      return;
    }

    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const headers = checkHeaders(sheet);

    if (headers === null) {
      console.log('InvalidHeaders');
      return;
    }

    const json = XLSX.utils.sheet_to_json(sheet, { defval: null });

    const keys = columns.map((col) => col.key);
    const usersList = json.map((row) => {
      const userData = {};
      keys.forEach((key) => {
        let value = null;
        const header = columns
          .find((c) => c.key === key)
          .header.replace(/\s+/g, '');
        const rowKey = Object.keys(row).find(
          (k) =>
            k.replace(/\s+/g, '').localeCompare(header, undefined, {
              sensitivity: 'base',
            }) === 0
        );
        userData[key] = row[rowKey];
      });

      return {...userData, sourceRow: JSON.stringify(row)};
    });

    onLoad({ data: usersList, file: fileList[0], headers });
    // const data = json.filter(elem => )
  }

  return (
    <Wrapper>
      <Dragger
        fileList={fileList}
        accept={accept}
        beforeUpload={beforeUpload}
        className="dragger"
      >
        {' '}
        <InboxOutlined className="icon" />
        <p className="text">
          Haga clic o arrastre un archivo (*.xlsx) para importar datos
        </p>
      </Dragger>
    </Wrapper>
  );
}

export default UsersLoader;
