/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { LOCATION_CHANGE } from 'connected-react-router';
import produce, { setAutoFreeze } from 'immer';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import {
    CIBERSEGURIDAD_PROVISIONING_SUBMIT,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED,
    CIBERSEGURIDAD_HISTORY_FETCH,
    CIBERSEGURIDAD_HISTORY_FETCH_FAILED,
    CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED,
    CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED,
} from './types';

const initialState = {
    ciberseguridadProvisioningSubmitRequested: false,
    ciberseguridadProvisioningSubmitSucceeded: false,
    ciberseguridadProvisioningSubmitFailed: false,
    ciberseguridadHistoryFetchRequested: false,
    ciberseguridadHistoryFetchSucceeded: false,
    ciberseguridadHistoryFetchFailed: false,
    ciberseguridadHistoryData: [],
};

const persistConfig = {
    key: 'ciberseguridad',
    storage: localForage,
    blacklist: [
        'ciberseguridadProvisioningSubmitRequested',
        'ciberseguridadProvisioningSubmitSucceeded',
        'ciberseguridadProvisioningSubmitFailed',
        'ciberseguridadHistoryFetchRequested',
        'ciberseguridadHistoryFetchSucceeded',
        'ciberseguridadHistoryFetchFailed',
        'ciberseguridadHistoryData',
    ],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
    switch (action.type) {
        case CIBERSEGURIDAD_PROVISIONING_SUBMIT:
            draft.ciberseguridadProvisioningSubmitRequested = false;
            draft.ciberseguridadProvisioningSubmitSucceeded = false;
            draft.ciberseguridadProvisioningSubmitFailed = false;
            break;

        case CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED:
            draft.ciberseguridadProvisioningSubmitRequested = true;
            draft.ciberseguridadProvisioningSubmitSucceeded = false;
            draft.ciberseguridadProvisioningSubmitFailed = false;
            break;

        case CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED:
            draft.ciberseguridadProvisioningSubmitRequested = false;
            draft.ciberseguridadProvisioningSubmitSucceeded = true;
            draft.ciberseguridadProvisioningSubmitFailed = false;
            break;

        case CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED:
            draft.ciberseguridadProvisioningSubmitRequested = false;
            draft.ciberseguridadProvisioningSubmitFailed = true;
            draft.ciberseguridadProvisioningSubmitRequested = false;
            break;

        case CIBERSEGURIDAD_HISTORY_FETCH:
            draft.ciberseguridadHistoryFetchRequested = false;
            draft.ciberseguridadHistoryFetchSucceeded = false;
            draft.ciberseguridadHistoryFetchFailed = false;
            break;

        case CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED:
            draft.ciberseguridadHistoryFetchRequested = true;
            draft.ciberseguridadHistoryFetchSucceeded = false;
            draft.ciberseguridadHistoryFetchFailed = false;
            break;

        case CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED:
            draft.ciberseguridadHistoryData = action.data;
            draft.ciberseguridadHistoryFetchRequested = false;
            draft.ciberseguridadHistoryFetchSucceeded = true;
            draft.ciberseguridadHistoryFetchFailed = false;
            break;

        case CIBERSEGURIDAD_HISTORY_FETCH_FAILED:
            draft.ciberseguridadHistoryFetchRequested = false;
            draft.ciberseguridadHistoryFetchFailed = true;
            draft.ciberseguridadHistoryFetchRequested = false;
            break;

        case LOCATION_CHANGE:
            draft.ciberseguridadProvisioningSubmitRequested = false;
            draft.ciberseguridadProvisioningSubmitSucceeded = false;
            draft.ciberseguridadProvisioningSubmitFailed = false;
            draft.ciberseguridadHistoryFetchRequested = false;
            draft.ciberseguridadHistoryFetchSucceeded = false;
            draft.ciberseguridadHistoryFetchFailed = false;
            break;
    }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);