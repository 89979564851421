/* eslint-disable import/prefer-default-export */

import { SMARTSUPPORT_PROVISIONING_SUBMIT, SMARTSUPPORT_PROVISIONING_SUBMIT_FAILED, SMARTSUPPORT_PROVISIONING_SUBMIT_REQUESTED, SMARTSUPPORT_PROVISIONING_SUBMIT_SUCCEEDED } from './types';

export function smartsupportProvisioningSubmitAction(data, file) {
    return {
        type: SMARTSUPPORT_PROVISIONING_SUBMIT,
        data,
        file,
    };
}

export function smartsupportProvisioningSubmitRequestedAction() {
    return {
        type: SMARTSUPPORT_PROVISIONING_SUBMIT_REQUESTED,
    };
}

export function smartsupportProvisioningSubmitSucceededdAction() {
    return {
        type: SMARTSUPPORT_PROVISIONING_SUBMIT_SUCCEEDED,
    };
}

export function smartsupportProvisioningSubmitFailedAction(error) {
    return {
        type: SMARTSUPPORT_PROVISIONING_SUBMIT_FAILED,
        error,
    };
}