/* eslint-disable no-underscore-dangle */

import { createSelector } from 'reselect';

import { initialState } from './reducers';

const _state = (state) => state.shared || initialState;

export const makeProjectCodeGetAllSubmitRequestedSelector = () => createSelector(_state, (state) => state.projectCodeGetAllSubmitRequested);
export const makeProjectCodeGetAllSubmitSucceededSelector = () => createSelector(_state, (state) => state.projectCodeGetAllSubmitSucceeded);
export const makeProjectCodeGetAllSubmitFailedSelector = () => createSelector(_state, (state) => state.projectCodeGetAllSubmitFailed);
export const makeProjectCodeListSelector = () => createSelector(_state, (state) => state.projectCodeList);
