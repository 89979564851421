import React from 'react';
import { useIdentity } from 'fractalia-provisioner/identity';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Button, Icons } from 'fractalia-design';

import {
  makeSubmissionHistoryFetchRequestedSelector,
  makeSubmissionHistoryFetchSucceededSelector,
  makeSubmissionHistoryFetchFailedSelector,
  makeSubmissionHistoryDataSelector,
} from '../store/selectors';

import { submissionHistoryFetchAction } from '../store/actions';

const { DownloadOutlined, ClockCircleOutlined } = Icons;

const submissionHistoryTableColumns = [
  {
    title: 'Proyecto',
    key: 'project',
    dataIndex: 'project',
    width: 140,
  },
  {
    title: 'Tipo',
    key: 'partner',
    dataIndex: 'partner',
    width: 140,
  },
  {
    title: 'Fecha Subido',
    key: 'uploadDate',
    dataIndex: 'uploadDate',
    render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    width: 170,
  },
  {
    title: <ClockCircleOutlined />,
    key: 'pending',
    dataIndex: 'pending',
    width: 100,
  },
  {
    title: 'Correctos',
    key: 'success',
    dataIndex: 'success',
    width: 100,
  },
  {
    title: 'Incorrectos',
    key: 'failure',
    dataIndex: 'failure',
    width: 100,
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
    width: 100,
  },
  {
    title: 'Excel File',
    key: 'downloadUrl',
    render: (text, record, index) => (
      <Button
        type="link"
        href={`${
          process.env.REACT_APP_API_PROVISION_URL
        }/provision/${record.partner.toLowerCase()}/users/${record.userId}`}
      >
        <DownloadOutlined />
      </Button>
    ),
  },
];

function useSubmissionHistory() {
  /**
   * hooks
   */
  const { user } = useIdentity();
  const dispatch = useDispatch();

  /**
   * selectors
   */
  const {
    submissionHistoryFetchRequested,
    submissionHistoryFetchSucceeded,
    submissionHistoryFetchFailed,
    submissionHistoryData,
  } = useSelector(
    createStructuredSelector({
      submissionHistoryFetchRequested: makeSubmissionHistoryFetchRequestedSelector(),
      submissionHistoryFetchSucceeded: makeSubmissionHistoryFetchSucceededSelector(),
      submissionHistoryFetchFailed: makeSubmissionHistoryFetchFailedSelector(),
      submissionHistoryData: makeSubmissionHistoryDataSelector(),
    })
  );

  /**
   * variables
   */
  const { projects } = user;

  /**
   * functions
   */

  const isCibeguridadProject = (project) => {
    return (
      !!project ||
      (project.partner
        ? project.partner.toLowerCase() === 'ciberseguridad'
        : false)
    );
  };

  const isLegacyProject = (project) => {
    return (
      !!project ||
      (project.partner ? project.partner.toLowerCase() === 'legacy' : false)
    );
  };

  const isSmartsupportProject = (project) => {
    return (
      !!project ||
      (project.partner
        ? project.partner.toLowerCase() === 'smartsupport'
        : false)
    );
  };

  function submissionHistoryFetch() {
    const { userId } = user;
    const fetchCiberseguridad = projects.some(isCibeguridadProject);
    const fetchLegacy = projects.some(isLegacyProject);
    const fetchSmartsupport = projects.some(isSmartsupportProject);
    dispatch(
      submissionHistoryFetchAction({
        userId,
        fetchCiberseguridad,
        fetchLegacy,
        fetchSmartsupport,
      })
    );
  }

  // function sort(data) {
  //   return data.sort((a, b) => {
  //     // if (moment(a.uploadDate).isBefore(b.uploadDate)) {
  //     console.log(moment(a.uploadDate));
  //     if (moment(a.uploadDate).isBefore(b.uploadDate)) {
  //       return -1;
  //     } else if (moment(a.uploadDate).isAfter(b.uploadDate)) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }
  function sort(data) {
    return data.sort(
      (a, b) => moment(b.uploadDate).unix() - moment(a.uploadDate).unix()
    );
  }

  return {
    submissionHistoryFetch,
    submissionHistoryFetchRequested,
    submissionHistoryFetchSucceeded,
    submissionHistoryFetchFailed,
    submissionHistoryData: sort(submissionHistoryData),
    submissionHistoryTableColumns,
  };
}

export default useSubmissionHistory;
