/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { LOCATION_CHANGE } from 'connected-react-router';
import produce, { setAutoFreeze } from 'immer';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import { PROJECTCODE_GETALL_SUBMIT, PROJECTCODE_GETALL_SUBMIT_FAILED, PROJECTCODE_GETALL_SUBMIT_REQUESTED, PROJECTCODE_GETALL_SUBMIT_SUCCEEDED } from './types';

const initialState = {
  projectCodeGetAllSubmitRequested: false,
  projectCodeGetAllSubmitSucceeded: false,
  projectCodeGetAllSubmitFailed: false,
  projectCodeList: [],
};

const persistConfig = {
  key: 'smartsupport',
  storage: localForage,
  blacklist: ['projectCodeGetAllSubmitRequested', 'projectCodeGetAllSubmitSucceeded', 'projectCodeGetAllSubmitFailed', 'projectCodeList'],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case PROJECTCODE_GETALL_SUBMIT:
      draft.projectCodeGetAllSubmitRequested = false;
      draft.projectCodeGetAllSubmitSucceeded = false;
      draft.projectCodeGetAllSubmitFailed = false;
      draft.projectCodeList = [];
      break;

    case PROJECTCODE_GETALL_SUBMIT_REQUESTED:
      draft.projectCodeGetAllSubmitRequested = true;
      draft.projectCodeGetAllSubmitSucceeded = false;
      draft.projectCodeGetAllSubmitFailed = false;
      draft.projectCodeList = [];
      break;

    case PROJECTCODE_GETALL_SUBMIT_SUCCEEDED:
      draft.projectCodeGetAllSubmitRequested = false;
      draft.projectCodeGetAllSubmitSucceeded = true;
      draft.projectCodeGetAllSubmitFailed = false;
      draft.projectCodeList = action.data;
      break;

    case PROJECTCODE_GETALL_SUBMIT_FAILED:
      draft.projectCodeGetAllSubmitRequested = false;
      draft.projectCodeGetAllSubmitFailed = true;
      draft.projectCodeGetAllSubmitRequested = false;
      draft.projectCodeList = [];
      break;

    case LOCATION_CHANGE:
      console.log('location change');
      draft.projectCodeGetAllSubmitRequested = false;
      draft.projectCodeGetAllSubmitSucceeded = false;
      draft.projectCodeGetAllSubmitFailed = false;
      draft.projectCodeList = [];
      break;
  }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);
