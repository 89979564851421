import { all, call, put, takeLatest } from 'redux-saga/effects';
import { projectCodeGetAllSubmitFailedAction, projectCodeGetAllSubmitRequestedAction, projectCodeGetAllSubmitSucceededdAction } from './actions';

import { PROJECTCODE_GETALL_SUBMIT } from './types';
import SharedAPI from '../api/SharedAPI';

const getAllSubmitAsync = async () =>
  // eslint-disable-next-line no-return-await
  await SharedAPI
    .submitGetAll()
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

function* getAllSubmitSaga() {
  try {

    console.log(SharedAPI);
    console.log(SharedAPI);
    console.log(SharedAPI);
    console.log(SharedAPI);
    // requested
    yield put(projectCodeGetAllSubmitRequestedAction());

    // request
    const data = yield call(getAllSubmitAsync);
    console.log(data);
    // succeeded
    yield put(projectCodeGetAllSubmitSucceededdAction(data));

  } catch (error) {
    // Failed
    yield put(projectCodeGetAllSubmitFailedAction(error));
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeLatest(PROJECTCODE_GETALL_SUBMIT, getAllSubmitSaga)]);
}
