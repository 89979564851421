import {
  makeSmartsupportProvisioningSubmitFailedSelector,
  makeSmartsupportProvisioningSubmitRequestedSelector,
  makeSmartsupportProvisioningSubmitSucceededSelector,
} from '../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { createStructuredSelector } from 'reselect';
import { smartsupportProvisioningSubmitAction } from '../store/actions';

function useSmartsupport() {
  const dispatch = useDispatch();

  const {
    smartsupportProvisioningSubmitRequested,
    smartsupportProvisioningSubmitSucceeded,
    smartsupportProvisioningSubmitFailed,
  } = useSelector(
    createStructuredSelector({
      smartsupportProvisioningSubmitRequested: makeSmartsupportProvisioningSubmitRequestedSelector(),
      smartsupportProvisioningSubmitSucceeded: makeSmartsupportProvisioningSubmitSucceededSelector(),
      smartsupportProvisioningSubmitFailed: makeSmartsupportProvisioningSubmitFailedSelector(),
    })
  );

  function smartsupportProvisioningSubmit(data, file) {
    dispatch(smartsupportProvisioningSubmitAction(data, file));
  }
  return {
    smartsupportProvisioningSubmit,
    smartsupportProvisioningSubmitRequested,
    smartsupportProvisioningSubmitSucceeded,
    smartsupportProvisioningSubmitFailed,
  };
}

export default useSmartsupport;
