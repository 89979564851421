import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import XLSX from 'xlsx';

import {
  ciberseguridadProvisioningSubmitAction,
  ciberseguridadHistoryFetchAction,
} from '../store/actions';
import {
  makeCiberseguridadProvisioningSubmitFailedSelector,
  makeCiberseguridadProvisioningSubmitRequestedSelector,
  makeCiberseguridadProvisioningSubmitSucceededSelector,
  makeCiberseguridadHistoryFetchFailedSelector,
  makeCiberseguridadHistoryFetchRequestedSelector,
  makeCiberseguridadHistoryFetchSucceededSelector,
  makeCiberseguridadHistoryDataSelector,
} from '../store/selectors';

function useCiberseguridad() {
  const dispatch = useDispatch();

  const {
    ciberseguridadProvisioningSubmitRequested,
    ciberseguridadProvisioningSubmitSucceeded,
    ciberseguridadProvisioningSubmitFailed,
    ciberseguridadHistoryFetchRequested,
    ciberseguridadHistoryFetchSucceeded,
    ciberseguridadHistoryFetchFailed,
    ciberseguridadHistoryData,
  } = useSelector(
    createStructuredSelector({
      ciberseguridadProvisioningSubmitRequested: makeCiberseguridadProvisioningSubmitRequestedSelector(),
      ciberseguridadProvisioningSubmitSucceeded: makeCiberseguridadProvisioningSubmitSucceededSelector(),
      ciberseguridadProvisioningSubmitFailed: makeCiberseguridadProvisioningSubmitFailedSelector(),
      ciberseguridadHistoryFetchRequested: makeCiberseguridadHistoryFetchRequestedSelector(),
      ciberseguridadHistoryFetchSucceeded: makeCiberseguridadHistoryFetchSucceededSelector(),
      ciberseguridadHistoryFetchFailed: makeCiberseguridadHistoryFetchFailedSelector(),
      ciberseguridadHistoryData: makeCiberseguridadHistoryDataSelector(),
    })
  );

  function ciberseguridadProvisioningSubmit(data, provisioningSourceFile) {
    window.noop();
    dispatch(
      ciberseguridadProvisioningSubmitAction(data, provisioningSourceFile)
    );
  }

  function ciberseguridadHistoryFetch(applicationUserId) {
    dispatch(ciberseguridadHistoryFetchAction(applicationUserId));
  }

  return {
    ciberseguridadProvisioningSubmit,
    ciberseguridadProvisioningSubmitRequested,
    ciberseguridadProvisioningSubmitSucceeded,
    ciberseguridadProvisioningSubmitFailed,
    ciberseguridadHistoryFetch,
    ciberseguridadHistoryFetchRequested,
    ciberseguridadHistoryFetchSucceeded,
    ciberseguridadHistoryFetchFailed,
    ciberseguridadHistoryData,
  };
}

export default useCiberseguridad;
