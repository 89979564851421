/* eslint-disable import/prefer-default-export */

import { PROJECTCODE_GETALL_SUBMIT, PROJECTCODE_GETALL_SUBMIT_FAILED, PROJECTCODE_GETALL_SUBMIT_REQUESTED, PROJECTCODE_GETALL_SUBMIT_SUCCEEDED } from './types';

export function projectCodeGetAllSubmitAction() {
  return {
    type: PROJECTCODE_GETALL_SUBMIT
  };
}

export function projectCodeGetAllSubmitRequestedAction() {
  return {
    type: PROJECTCODE_GETALL_SUBMIT_REQUESTED,
  };
}

export function projectCodeGetAllSubmitSucceededdAction(data) {
  return {
    type: PROJECTCODE_GETALL_SUBMIT_SUCCEEDED,
    data
  };
}

export function projectCodeGetAllSubmitFailedAction(error) {
  return {
    type: PROJECTCODE_GETALL_SUBMIT_FAILED,
    error,
  };
}
