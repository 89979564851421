const columns = [{
        key: 'customerRef',
        header: 'CustomerRef',
        required: false,
    },
    {
        key: 'licenseOrLogin',
        header: 'LicenseOrLogin',
        required: true,
        width: 250,
    },
    {
        key: 'email',
        header: 'Email',
        required: true,
        width: 250,
    },
    {
        key: 'fullname',
        header: 'Fullname',
        required: true,
        width: 250,
    },
    {
        key: 'address',
        header: 'Address',
        required: false,
        width: 250,
    },
    {
        key: 'phone',
        header: 'Phone',
        required: false,
        width: 120,
    },
    {
        key: 'authorizedFullname',
        header: 'AuthorizedFullname',
        required: false,
        width: 150,
    },
    {
        key: 'authorizedPhones',
        header: 'AuthorizedPhones',
        required: false,
        width: 150,
    },
    {
        key: 'companyId',
        header: 'CompanyId',
        required: true,
        width: 120,
    },
    {
        key: 'companyName',
        header: 'CompanyName',
        required: false,
        width: 150,
    },
    {
        key: 'companyAddress',
        header: 'CompanyAddress',
        required: false,
        width: 150,
    },
    {
        key: 'portalPentesting',
        header: 'PortalPentesting',
        required: false,
        width: 200,
    },
    {
        key: 'action',
        header: 'Action',
        required: true,
        width: 100,
    },
];

export default columns;