import CiberseguridadProvisionerPage from './pages/CiberseguridadPage';
import useCiberseguridad from './hooks/useCiberseguridad';

import * as actions from './store/actions';
import createReducer, { initialState } from './store/reducer';
import sagas from './store/sagas';
import * as selectors from './store/selectors';
import * as types from './store/types';

const store = {
    actions,
    createReducer,
    sagas,
    selectors,
    types,
};

export default { CiberseguridadProvisionerPage, useCiberseguridad };
export { CiberseguridadProvisionerPage };
export { useCiberseguridad };
export { store };