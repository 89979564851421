import React, { useEffect, useState } from 'react';

import useSubmissionHistory from '../../hooks/useSubmissionHistory';
import { useIdentity } from 'fractalia-provisioner/identity';
import UserProfile from '../../components/UserProfile';

import WelcomePageLayout from './WelcomePageLayout';
import WelcomePageContent from './WelcomePageContent';
// import WelcomePageTitle from '../components/WelcomePageTitle';
import { Spin } from 'fractalia-design';

import UserSubmissionHistoryWidget from '../../components/UserSubmissionHistoryWidget';
import UserProjectsPermissionWidget from '../../components/UserProjectsPermissionWidget';
import { css } from '@emotion/core';

const pageStyle = css`
  .page-content: {
    blackground-color: #fff;
  }
`;

function WelcomePage() {
  /**
   * constantes
   */
  const userSubmissionHistoryTableHeaderText = 'Histórico de ficheros subidos';
  const userProjectsAllowedTableHeaderText = 'Proyectos en los que puede provisionar usuarios';

  // hooks
  const { user } = useIdentity();
  const [permissionData, setPermissionData] = useState([]);
  const [submissionHistoryDataLocal, setSubmissionHistoryDataLocal] = useState([]);

  const {
    submissionHistoryFetch,
    submissionHistoryFetchRequested,
    submissionHistoryFetchSucceeded,
    submissionHistoryFetchFailed,
    submissionHistoryData,
  } = useSubmissionHistory();

  // effects
  useEffect(() => {
    submissionHistoryFetch();
    console.log('effect');
    setPermissionData(
      user.projects.map((project) => {
        return { description: project.description, partner: project.partner };
      })
    );
  }, []);

  useEffect(() => {
    setSubmissionHistoryDataLocal(submissionHistoryData);
  }, [submissionHistoryFetchSucceeded]);

  return (
    <WelcomePageLayout css={pageStyle}>
      <Spin spinning={submissionHistoryFetchRequested}>
        <WelcomePageContent>
          <div>
            <UserSubmissionHistoryWidget headerText={'Histórico de ficheros subidos'} data={submissionHistoryDataLocal} />
          </div>
          <div css={{ maxWidth: '80%' }}>
            <UserProjectsPermissionWidget headerText={'Proyectos en los que puede provisionar usuarios/licencias'} data={permissionData} />
          </div>
          <div>
            <h3 class="css-1tquyt-styles header">Últimos cambios realizados .-</h3>
            <ul>
              <li>
                2021-03-26
                <ul>
                  <li>Cambian algunos literales.</li>
                  <li>
                    Smartsupport
                    <p>
                      La plantilla excel incorpora una nueva columna llamada <b>"companyMaxNumLicensesAllowed"</b> de tipo numérico en la que se puede
                      introducir el número de hijos/empleados que pueden ser provisionados con la licencia/usuario que se va a dar de alta. Sólo para
                      el modelo multiusuario (usuarios que gestionan otros usuarios). <u>Opcional</u>
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                2021-03-25
                <ul>
                  <li>
                    Smartsupport
                    <p>
                      Las <i>únicas columnas obligatorias</i> pasan a ser <b>LOGIN</b> y <b>ACTION</b>. Sea consciente de que si un usuario/licencia
                      no se provisiona con email, no se le podrá enviar mail de bienvenida.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </WelcomePageContent>
      </Spin>
    </WelcomePageLayout>
  );
}

export default WelcomePage;
