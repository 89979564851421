/* eslint-disable no-underscore-dangle */

import { createSelector } from 'reselect';

import { initialState } from './reducer';

const _state = (state) => state.ciberseguridad || initialState;

export const makeCiberseguridadProvisioningSubmitRequestedSelector = () => createSelector(_state, (state) => state.ciberseguridadProvisioningSubmitRequested);
export const makeCiberseguridadProvisioningSubmitSucceededSelector = () => createSelector(_state, (state) => state.ciberseguridadProvisioningSubmitSucceeded);
export const makeCiberseguridadProvisioningSubmitFailedSelector = () => createSelector(_state, (state) => state.ciberseguridadProvisioningSubmitFailed);

export const makeCiberseguridadHistoryFetchRequestedSelector = () => createSelector(_state, (state) => state.ciberseguridadHistoryFetchRequested);
export const makeCiberseguridadHistoryFetchSucceededSelector = () => createSelector(_state, (state) => state.ciberseguridadHistoryFetchSucceeded);
export const makeCiberseguridadHistoryFetchFailedSelector = () => createSelector(_state, (state) => state.ciberseguridadHistoryFetchFailed);
export const makeCiberseguridadHistoryDataSelector = () => createSelector(_state, (state) => state.ciberseguridadHistoryData);