import { createSelector } from 'reselect';

import { initialState } from './reducers';

const _state = (state) => state.app || initialState;

export const makeCurrentMenuSelector = () => createSelector(_state, (state) => state.currentMenu);

export const makeSubmissionHistoryFetchRequestedSelector = () => createSelector(_state, (state) => state.submissionHistoryFetchRequested);
export const makeSubmissionHistoryFetchSucceededSelector = () => createSelector(_state, (state) => state.submissionHistoryFetchSucceeded);
export const makeSubmissionHistoryFetchFailedSelector = () => createSelector(_state, (state) => state.submissionHistoryFetchFailed);
export const makeSubmissionHistoryDataSelector = () => createSelector(_state, (state) => state.submissionHistoryData);