import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Icons } from 'fractalia-design';

const { UserOutlined } = Icons;

const UserProfileContainer = styled.div`
  display: inline-block;
`;

const UserName = styled.span`
  color: #1890ff;
  font-size: 1.2rem;
  font-weight: 500;
  vertical-align: middle;
  margin-left: 6px;
`;

function UserProfile() {
  return (
    <UserProfileContainer>
      <Avatar
        size="large"
        style={{
          backgroundColor: '#fff',
          color: '#1890ff',
          fontSize: '2.2rem',
        }}
        icon={<UserOutlined />}
      />
      <UserName>Nancy Demo</UserName>
    </UserProfileContainer>
  );
}

export default UserProfile;
