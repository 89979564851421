import { Icons } from 'fractalia-design';
import React from 'react';

const { HomeOutlined } = Icons;

const menus = [{
        key: 'home',
        path: '/',
        text: < HomeOutlined / > ,
        className: 'home-menu',
    },
    {
        key: 'smartsupport',
        path: '/smartsupport',
        text: 'Smartsupport',
        className: 'provisioner-menu',
    },
    {
        key: 'legacy',
        path: '/legacy',
        text: 'Legacy',
        className: 'provisioner-menu',
    },
    {
        key: 'ciberseguridad',
        path: '/ciberseguridad',
        text: 'Ciberseguridad',
        className: 'provisioner-menu',
    },
    {
        key: 'ciberalarma',
        path: '/ciberalarma',
        text: 'Ciberalarma',
        className: 'provisioner-menu',
    },
];

export default menus;