import React from 'react';
import { Table, Button, Icons, Tooltip, Tag } from 'fractalia-design';
import { css } from '@emotion/core';
import moment from 'moment';

const {
  DownloadOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudOutlined,
  FileExcelOutlined,
} = Icons;

const columns = [
  {
    title: 'Proyecto',
    key: 'project',
    dataIndex: 'project',
    width: 300,
    align: 'left',
  },
  {
    title: 'Tipo',
    key: 'partner',
    dataIndex: 'partner',
    width: 100,
    align: 'left',
  },
  {
    title: 'Fecha Subido',
    key: 'uploadDate',
    dataIndex: 'uploadDate',
    render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    width: 100,
    align: 'center',
  },
  {
    //
    key: 'pending',
    title: (
      <Tooltip title="Pendientes">
        <ClockCircleOutlined />
      </Tooltip>
    ),
    dataIndex: 'pending',
    width: 100,
    align: 'center',
  },
  {
    key: 'success',
    title: (
      <Tooltip title="Correctos">
        <CheckCircleOutlined />
      </Tooltip>
    ),
    dataIndex: 'success',
    width: 100,
    align: 'center',
  },
  {
    key: 'failure',
    title: (
      <Tooltip title="Incorrectos">
        <CloseCircleOutlined />
      </Tooltip>
    ),
    dataIndex: 'failure',
    render: (_, record) => {
      return record.failure > 0 ? (
        <span css={{ color: '#f5222d', fontWeight: '600' }}>
          {record.failure}
        </span>
      ) : (
        <span>{record.failure}</span>
      );
    },
    width: 100,
    align: 'center',
  },
  {
    key: 'total',
    title: (
      <Tooltip title="Total">
        <CloudOutlined />
      </Tooltip>
    ),
    dataIndex: 'total',
    width: 100,
    align: 'center',
  },
  {
    key: 'downloadUrl',
    title: (
      <Tooltip title="Descargar Archivo">
        <FileExcelOutlined />
      </Tooltip>
    ),
    render: (text, record, index) => (
      <Button
        type="link"
        href={`${
          process.env.REACT_APP_API_PROVISION_URL
        }/provision/${record.partner.toLowerCase()}/users/${
          record.submissionId
        }`}
      >
        <DownloadOutlined />
      </Button>
    ),
    width: 100,
    align: 'center',
  },
];

function PendingTableTitle() {
  return <Tooltip text="Tooltip">abc</Tooltip>;
}

function UserSubmissionHistoryTable({ data }) {
  const pagination = {
    pageSize: 5,
  };

  return (
    <Table
      css={{ margin: '20px 0px' }}
      dataSource={data}
      columns={columns}
      pagination={pagination}
      size="small"
      bordered
    />
  );
}

export default UserSubmissionHistoryTable;
