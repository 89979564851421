import React from 'react'
import styled from '@emotion/styled';
import { Layout } from 'antd';

const { Content: ContentBase } = Layout;

const Content = styled(ContentBase)`
  display: flex;      
  align-self: center;  
  background-color: #fff;
`;


export default Content
