import React from 'react';
import { Table, Button } from 'fractalia-design';
import { css } from '@emotion/core';

const columns = [
  {
    title: 'Proyecto',
    key: 'description',
    dataIndex: 'description',
    width: 230,
    align: 'left',
  },
  {
    title: 'Tipo',
    key: 'partner',
    width: 50,
    align: 'left',
    dataIndex: 'partner',
  },
];

function UserProjectPermissionTable({ data, pagination }) {
  return (
    <Table
      css={{ margin: '20px 0px' }}
      dataSource={data}
      columns={columns}
      pagination={pagination}
      size="small"
      bordered
    />
  );
}

export default UserProjectPermissionTable;
