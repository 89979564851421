import Axios from 'axios';
import FormData from 'form-data';

const IdentityAPI = {
    /**
     * authenticate
     *
     * @param {*} username
     * @param {*} password
     * @returns
     */
    async authenticate(username, password) {
        return fetch(`${process.env.REACT_APP_API_IDENTITY_URL}/identity/login`, {
            // return fetch('https://localhost:5001/identity-service/identity/login', {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(`username=${username}&password=${password}&grant_type=client_credentials&scope=provision&client_id=provision&client_secret=36EF-FRT0-4Y09-8PCA-XCTHBC2F12W`),
        });
        // return response.json();
        // return Axios({
        //   method: 'post',
        //   baseURL: 'http://localhost:5001/identity-service',
        //   // baseURL: 'https://api.provision.devonlineassist.me/identity-service',
        //   url: 'identity/login',
        //   data: { username, password },
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // });
        // const form = new FormData();
        // form.append('username', username);
        // form.append('password', password);
        // form.append('grant_type', 'client_credentials');
        // form.append('scope', 'provision');
        // form.append('client_id', 'provision');
        // form.append('client_secret', '36FF-FRT0-4Y09-8PCA-XCTHBC2F12W');
        // return Axios({
        //   method: 'POST',
        //   baseURL: 'http://localhost:5001/identity-service',
        //   url: 'identity/login',
        //   data: JSON.stringify({
        //     username: username,
        //     password: password,
        //     grant_type: 'client_credentials',
        //     scope: 'provision',
        //     client_id: 'provision',
        //     client_secret: '36FF-FRT0-4Y09-8PCA-XCTHBC2F12W',
        //   }),
        // });
        // Default options are marked with *
        // const data = {
        //   username: username,
        //   password: password,
        //   grant_type: 'client_credentials',
        //   scope: 'provision',
        //   client_id: 'provision',
        //   client_secret: '36FF-FRT0-4Y09-8PCA-XCTHBC2F12W',
        // };
        // const response = await fetch('http://localhost:5001/identity-service/identity/login', {
        //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //   // mode: 'cors', // no-cors, *cors, same-origin
        //   // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //   // credentials: 'same-origin', // include, *same-origin, omit
        //   // headers: {
        //   //   // 'Content-Type': 'application/json',
        //   //   'Content-Type': 'application/x-www-form-urlencoded',
        //   // },
        //   // redirect: 'follow', // manual, *follow, error
        //   // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //   body: new URLSearchParams('username=vik&password=passsss&grant_type=client_credentials&scope=provision&client_id=provision&client_secret=36EF-FRT0-4Y09-8PCA-XCTHBC2F12W'), // body data type must match "Content-Type" header
        // });
        // return response.json();
    },
};

export default IdentityAPI;