import { Route as BaseRoute, Redirect } from 'react-router-dom';

import React, { Children } from 'react';
import { useIdentity } from 'fractalia-provisioner/identity';
import Oidc from 'oidc-client';

function Route({ component: Component, secured, ...rest }) {
  const { isAuthenticated, user } = useIdentity();

  // console.log('identity', identity);
  if (!secured) {
    return <BaseRoute {...rest} component={Component} />;
  }

  // const { username, password } = values;
  // var config = {
  //   authority: 'https://localhost:5001',
  //   client_id: 'js',
  //   redirect_uri: 'http://localhost:3000/identity/login',
  //   response_type: 'code',
  //   scope: 'openid',
  //   post_logout_redirect_uri: 'https://localhost:5003/index.html',
  // };
  // var mgr = new Oidc.UserManager(config);

  // mgr.getUser().then(function (user) {
  //   if (user) {
  //     console.log('User logged in', user.profile);
  //   } else {
  //     console.log('User not logged in');
  //   }
  // });

  // const isAuthenticated = identity
  //   ?
  //   : false;

  return (
    <BaseRoute
      {...rest}
      render={(props) =>
        isAuthenticated && !!user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/identity/login',
            }}
          />
        )
      }
    />
  );
}

export default Route;
