const columns = [{
        key: 'customerRef',
        header: 'CustomerRef',
        required: false,
    },
    {
        key: 'name',
        header: 'Name',
        required: true,
    },
    {
        key: 'surname1',
        header: 'Surname1',
        required: false,
    },
    {
        key: 'surname1',
        header: 'Surname1',
        required: false,
    },
    {
        key: 'email',
        header: 'Email',
        required: true,
    },
    {
        key: 'phone',
        header: 'Phone',
        required: false,
    },
    {
        key: 'action',
        header: 'Action',
        required: false,
    },
];

export default columns;