import Axios from 'axios';

const CiberseguridadAPI = {
    submitProvisioning(transactions, sourceFile) {
        const form = new FormData();
        // form.append('transactions', transactions);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].customerRef) form.append(`ciberseguridadUserDtos[${i}].customerRef`, transactions[i].customerRef);
            if (transactions[i].licenseOrLogin) form.append(`ciberseguridadUserDtos[${i}].licenseOrLogin`, transactions[i].licenseOrLogin);
            if (transactions[i].email) form.append(`ciberseguridadUserDtos[${i}].email`, transactions[i].email);
            if (transactions[i].fullname) form.append(`ciberseguridadUserDtos[${i}].fullname`, transactions[i].fullname);
            if (transactions[i].address) form.append(`ciberseguridadUserDtos[${i}].address`, transactions[i].address);
            if (transactions[i].phone) form.append(`ciberseguridadUserDtos[${i}].phone`, transactions[i].phone);
            if (transactions[i].authorizedFullname) form.append(`ciberseguridadUserDtos[${i}].authorizedFullname`, transactions[i].authorizedFullname);
            if (transactions[i].authorizedPhones) form.append(`ciberseguridadUserDtos[${i}].authorizedPhones`, transactions[i].authorizedPhones);
            if (transactions[i].companyId) form.append(`ciberseguridadUserDtos[${i}].companyId`, transactions[i].companyId);
            if (transactions[i].companyName) form.append(`ciberseguridadUserDtos[${i}].companyName`, transactions[i].companyName);
            if (transactions[i].companyAddress) form.append(`ciberseguridadUserDtos[${i}].companyAddress`, transactions[i].companyAddress);
            if (transactions[i].portalPentesting) form.append(`ciberseguridadUserDtos[${i}].portalPentesting`, transactions[i].portalPentesting);
            if (transactions[i].action) form.append(`ciberseguridadUserDtos[${i}].action`, transactions[i].action);
            if (transactions[i].projectCode) form.append(`ciberseguridadUserDtos[${i}].projectCode`, transactions[i].projectCode);
            if (transactions[i].applicationUserId) form.append(`ciberseguridadUserDtos[${i}].applicationUserId`, transactions[i].userId);
            if (transactions[i].sourceRow) form.append(`ciberseguridadUserDtos[${i}].sourceRow`, transactions[i].sourceRow);
        }
        form.append('ciberseguridadUserFileDto', sourceFile);

        return Axios({
            method: 'post',
            // baseURL: 'https://localhost:5003',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/ciberseguridad/users`,
            data: form,
            timeout: 30000,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    historyFetch(applicationUserId) {
        return Axios({
            method: 'get',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/ciberseguridad/history?applicationUserId=${applicationUserId}`,
        });
    },
};

export default CiberseguridadAPI;