import React from 'react';
import { css } from '@emotion/core';
import UserSubmissionHistoryTable from './UserSubmissionHistoryTable.jsx';

const styles = css`
  .header {
    font-size: 1.1rem;
    color: #1890ff;
  }
`;

function UserSubmissionHistoryWidget({ headerText, data }) {
  return (
    <div css={styles}>
      <div className="header">{headerText}</div>
      <UserSubmissionHistoryTable data={data} />
    </div>
  );
}

export default UserSubmissionHistoryWidget;
