const SET_CURRENT_MENU = 'SET_CURRENT_MENU';

const SUBMISSION_HISTORY_FETCH = 'SUBMISSION_HISTORY_FETCH';
const SUBMISSION_HISTORY_FETCH_REQUESTED = 'SUBMISSION_HISTORY_FETCH_REQUESTED';
const SUBMISSION_HISTORY_FETCH_SUCCEEDED = 'SUBMISSION_HISTORY_FETCH_SUCCEEDED';
const SUBMISSION_HISTORY_FETCH_FAILED = 'SUBMISSION_HISTORY_FETCH_FAILED';

export { SET_CURRENT_MENU };

export { SUBMISSION_HISTORY_FETCH };
export { SUBMISSION_HISTORY_FETCH_REQUESTED };
export { SUBMISSION_HISTORY_FETCH_SUCCEEDED };
export { SUBMISSION_HISTORY_FETCH_FAILED };