import React from 'react';
import { Layout } from 'fractalia-design';
import { css } from '@emotion/core';

const { Content } = Layout;

function WelcomePageContent({ children }) {
  const style = css`
    display: block;
    padding: 0px 200px;
  `;
  return <Content css={style}>{children}</Content>;
}

export default WelcomePageContent;
