import { all, call, put, takeLatest } from 'redux-saga/effects';

import CiberalarmaAPI from '../api/CiberalarmaAPI';
import { ciberalarmaProvisioningSubmitFailedAction, ciberalarmaProvisioningSubmitRequestedAction, ciberalarmaProvisioningSubmitSucceededAction } from './actions';
import { CIBERALARMA_PROVISIONING_SUBMIT } from './types';

const provisioningSubmitAsync = async(data, provisioningSourceFile) =>
    // eslint-disable-next-line no-return-await
    await CiberalarmaAPI.submitProvisioning(data, provisioningSourceFile)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* provisioningSubmitSaga(action) {
    try {
        const { data, provisioningSourceFile } = action;
        // requested
        yield put(ciberalarmaProvisioningSubmitRequestedAction());
        yield call(provisioningSubmitAsync, data, provisioningSourceFile);
        // succeeded
        yield put(ciberalarmaProvisioningSubmitSucceededAction());
    } catch (error) {
        // error
        yield put(ciberalarmaProvisioningSubmitFailedAction(error));
    }
}

export default function* rootSaga() {
    yield all([takeLatest(CIBERALARMA_PROVISIONING_SUBMIT, provisioningSubmitSaga)]);
}