/* eslint-disable import/order */
/*
 * @Author: gvillena
 * @Date: 2020-06-08 14:23:48
 * @Last Modified by: gvillena
 * @Last Modified time: 2020-06-26 03:21:18
 */

import './styles/app.css';

import { Link, Redirect, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useApp } from './hooks/useApp';
import { FractaliaLogo, Layout as LayoutBase, Divider, Button } from 'fractalia-design';
import Menu from './components/Menu';
import menus from './menus';
import routes from './routes';
import styled from '@emotion/styled';
import { useIdentity } from 'fractalia-provisioner/identity';
import Route from './components/Route';

const Layout = styled(LayoutBase)`
  background-color: #fff;
  min-height: 100vh;
`;

const { Content: ContentBase, Header: HeaderBase } = LayoutBase;

const Header = styled(HeaderBase)`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled(ContentBase)`
  background-color: #fff;
  width: 100%;
`;

//========================================================================================
/*                                                                                      *
 *                                          App                                         *
 *                                                                                      */
//========================================================================================

function App() {
  const { currentMenu, onSetCurrentMenu } = useApp();
  const { isAuthenticated, user, logout } = useIdentity();

  useEffect(() => {
    // onSetCurrentMenu('home');
    // logout();
  }, []);

  function getMenuDisabled(key) {
    const { projects } = user;
    if (!projects) {
      return false;
    }
    const partners = projects.reduce(
      (acc, val) => {
        const { partner } = val;
        if (partner) {
          if (!acc.includes(partner.toLowerCase())) {
            return [...acc, partner.toLowerCase()];
          }
        }
        return acc;
      },
      ['home']
    );
    return !partners.includes(key);
  }

  return (
    <Layout>
      {isAuthenticated && (
        <>
          <Header>
            <FractaliaLogo height={40} />
            <div>
              {user.fullname}{' '}
              <span>
                <Button type="link" onClick={logout}>
                  (Salir)
                </Button>
              </span>
            </div>
          </Header>

          <Menu onClick={(e) => onSetCurrentMenu(e.key)} selectedKeys={currentMenu ? [currentMenu] : ['home']} mode="horizontal">
            {menus.map((menu) => (
              <Menu.Item key={menu.key} icon={menu.icon} disabled={getMenuDisabled(menu.key)}>
                <Link to={menu.path}>{menu.text}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </>
      )}

      <Content>
        <Switch>
          {routes.map((route) => (
            <Route path={route.path} component={route.component} secured={route.secured} exact={route.exact} />
          ))}
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
      </Content>
    </Layout>
  );
}

export default App;
