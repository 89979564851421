/* eslint-disable react/prop-types */

import configureStore, { initialState } from '../store';

import { ConnectedRouter } from 'connected-react-router';
import { IdentityProvider } from 'fractalia-provisioner/identity';
import { AppProvider } from '../app/hooks/useApp';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import history from '../utils/history';

const { store, persistor } = configureStore(initialState);

export default function AppContext({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <IdentityProvider>
            <AppProvider>
              {children}
            </AppProvider>
          </IdentityProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}
