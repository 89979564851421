import Axios from 'axios';

const SharedAPI = {
  submitGetAll() {
    return Axios({
      // baseURL: 'https://localhost:5003',
      baseURL: 'https://api.provision.devonlineassist.me/provision-service',
      url: '/provision/projectcode',
      timeout: 30000
    });
  },
};

export default SharedAPI;
