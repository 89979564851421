/* eslint-disable no-underscore-dangle */

import { createSelector } from 'reselect';

import { initialState } from './reducer';

const _state = (state) => state.smartSupport || initialState;

export const makeSmartsupportProvisioningSubmitRequestedSelector = () => createSelector(_state, (state) => state.smartsupportProvisioningSubmitRequested);
export const makeSmartsupportProvisioningSubmitSucceededSelector = () => createSelector(_state, (state) => state.smartsupportProvisioningSubmitSucceeded);
export const makeSmartsupportProvisioningSubmitFailedSelector = () => createSelector(_state, (state) => state.smartsupportProvisioningSubmitFailed);
