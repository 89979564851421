/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

import { USER_LOGIN, USER_LOGIN_FAILED, USER_LOGIN_REQUESTED, USER_LOGIN_SUCCEEDED, USER_LOGOUT } from './types';
import produce, { setAutoFreeze } from 'immer';

import { LOCATION_CHANGE } from 'connected-react-router';
import localForage from 'localforage';
import moment from 'moment';
import { persistReducer } from 'redux-persist';

const initialState = {
    userLoginRequested: false,
    userLoginSucceeded: false,
    userLoginFailed: false,
    userLoginFailedError: '',
    user: null,
    projectCodes: [],
    token: null,
    isAuthenticated: false,
};

const persistConfig = {
    key: 'identity',
    storage: localForage,
    blacklist: ['userLoginRequested', 'userLoginSucceeded', 'userLoginFailed', 'userLoginFailedError'],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
    switch (action.type) {
        case USER_LOGIN:
            draft.userLoginRequested = false;
            draft.userLoginSucceeded = false;
            draft.userLoginFailed = false;
            draft.userLoginFailedError = '';
            draft.user = null;
            draft.token = null;
            draft.isAuthenticated = false;
            draft.projectCodes = [];
            break;

        case USER_LOGIN_REQUESTED:
            draft.userLoginRequested = true;
            draft.userLoginSucceeded = false;
            draft.userLoginFailed = false;
            draft.userLoginFailedError = '';
            draft.user = null;
            draft.token = null;
            draft.isAuthenticated = false;
            draft.projectCodes = [];
            break;

        case USER_LOGIN_SUCCEEDED:
            draft.user = action.user;
            draft.isAuthenticated = true;
            draft.userLoginSucceeded = true;
            break;

        case USER_LOGIN_FAILED:
            draft.userLoginFailedErrorMessage = action.error;
            draft.userLoginRequested = false;
            draft.userLoginFailed = true;
            draft.user = '';
            draft.projectCodes = [];
            break;
        case USER_LOGOUT:
            draft.user = null;
            draft.isAuthenticated = false;
            break;

        case LOCATION_CHANGE:
            draft.userLoginRequested = false;
            draft.userLoginSucceeded = false;
            draft.userLoginFailed = false;
            draft.userLoginFailedError = '';
            break;
    }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);