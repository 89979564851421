import { all, call, put, takeLatest } from 'redux-saga/effects';
import { smartsupportProvisioningSubmitFailedAction, smartsupportProvisioningSubmitRequestedAction, smartsupportProvisioningSubmitSucceededdAction } from './actions';

import { SMARTSUPPORT_PROVISIONING_SUBMIT } from './types';
import smartsupportClient from '../shared/client';

const provisioningSubmitAsync = async(data, file) =>
    // eslint-disable-next-line no-return-await
    await smartsupportClient
    .submitProvisioning(data, file)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* provisioningSubmitSaga(action) {
    try {
        const { data, file } = action;

        // requested
        yield put(smartsupportProvisioningSubmitRequestedAction());

        // request
        yield call(provisioningSubmitAsync, data, file);

        // succeeded
        yield put(smartsupportProvisioningSubmitSucceededdAction());
    } catch (error) {
        // Failed
        yield put(smartsupportProvisioningSubmitFailedAction(error));
    }
}

export default function* rootSaga() {
    yield all([takeLatest(SMARTSUPPORT_PROVISIONING_SUBMIT, provisioningSubmitSaga)]);
}