const columns = [{
    key: 'customerRef',
    header: 'CustomerRef',
    required: false,
},
{
    key: 'login',
    header: 'Login',
    required: true,
},
{
    key: 'name',
    header: 'Name',
    required: false,
},
{
    key: 'surname',
    header: 'Surname',
    required: false,
},
{
    key: 'email',
    header: 'Email',
    required: false,
},
{
    key: 'address',
    header: 'Address',
    required: false,
},
{
    key: 'city',
    header: 'City',
    required: false,
},
{
    key: 'country',
    header: 'Country',
    required: false,
},
{
    key: 'phone',
    header: 'Phone',
    required: false,
},
{
    key: 'action',
    header: 'Action',
    required: false,
},
    // {
    //   key: 'projectCode',
    //   header: 'Project Code',
    //   required: false,
    // },
];

export default columns;