import React from 'react';
import { Select } from 'fractalia-design';

const { Option } = Select;

function ProjectCodeSelector({ options, onChange, value }) {
  return (
    <Select
      placeholder="Seleccione la plataforma a provisionar"
      onSelect={(key) => onChange(key)}
      style={{ width: '26%' }}
      value={value}
    >
      {options.map((o) => (
        <Option value={o.value}>{o.label}</Option>
      ))}
    </Select>
  );
}

export default ProjectCodeSelector;
