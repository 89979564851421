const CIBERSEGURIDAD_PROVISIONING_SUBMIT = 'CIBERSEGURIDAD_PROVISIONING_SUBMIT';
const CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED = 'CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED';
const CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED = 'CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED';
const CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED = 'CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED';

const CIBERSEGURIDAD_HISTORY_FETCH = 'CIBERSEGURIDAD_HISTORY_FETCH';
const CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED = 'CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED';
const CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED = 'CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED';
const CIBERSEGURIDAD_HISTORY_FETCH_FAILED = 'CIBERSEGURIDAD_HISTORY_FETCH_FAILED';

export { CIBERSEGURIDAD_PROVISIONING_SUBMIT };
export { CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED };
export { CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED };
export { CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED };

export { CIBERSEGURIDAD_HISTORY_FETCH };
export { CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED };
export { CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED };
export { CIBERSEGURIDAD_HISTORY_FETCH_FAILED };