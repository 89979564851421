/* eslint-disable no-param-reassign */

import produce, { setAutoFreeze } from 'immer';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import { SET_CURRENT_MENU } from './types';

const initialState = {
  currentMenu: {},
};

const persistConfig = {
  key: 'global',
  storage: localForage,
  blacklist: ['currentMenu'],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CURRENT_MENU:
      draft.currentMenu = action.currentMenu;
      break;
  }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);
