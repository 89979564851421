/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */

import * as actions from '../store/actions';
import * as selectors from '../store/selectors';

import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { notification } from 'fractalia-design';
import { push } from 'connected-react-router';

export const IdentityContext = createContext();

export function IdentityProvider({ children }) {
  return (
    <IdentityContext.Provider value={useIdentityProvider()}>
      {children}
    </IdentityContext.Provider>
  );
}

export const useIdentity = () => {
  return useContext(IdentityContext);
};

const { makeUserSelector, makeIsAuthenticatedSelector, makeProjectCodesSelector } = selectors;

const { userLogoutAction } = actions;

function useIdentityProvider() {
  const { user, isAuthenticated, projectCodes } = useSelector(
    createStructuredSelector({
      user: makeUserSelector(),
      projectCodes: makeProjectCodesSelector(),
      isAuthenticated: makeIsAuthenticatedSelector(),
    })
  );

  const dispatch = useDispatch();

  function reset() {
    dispatch(userLogoutAction());
  }

  function logout() {
    dispatch(userLogoutAction());
    dispatch(push('/identity/login'));
  }

  return {
    user,
    projectCodes,
    isAuthenticated,
    logout,
    reset,
  };
}
