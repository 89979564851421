const LEGACY_PROVISIONING_SUBMIT = 'LEGACY_PROVISIONING_SUBMIT';
const LEGACY_PROVISIONING_SUBMIT_REQUESTED = 'LEGACY_PROVISIONING_SUBMIT_REQUESTED';
const LEGACY_PROVISIONING_SUBMIT_SUCCEEDED = 'LEGACY_PROVISIONING_SUBMIT_SUCCEEDED';
const LEGACY_PROVISIONING_SUBMIT_FAILED = 'LEGACY_PROVISIONING_SUBMIT_FAILED';

const LEGACY_HISTORY_FETCH = 'LEGACY_HISTORY_FETCH';
const LEGACY_HISTORY_FETCH_REQUESTED = 'LEGACY_HISTORY_FETCH_REQUESTED';
const LEGACY_HISTORY_FETCH_SUCCEEDED = 'LEGACY_HISTORY_FETCH_SUCCEEDED';
const LEGACY_HISTORY_FETCH_FAILED = 'LEGACY_HISTORY_FETCH_FAILED';

export { LEGACY_HISTORY_FETCH };
export { LEGACY_HISTORY_FETCH_REQUESTED };
export { LEGACY_HISTORY_FETCH_SUCCEEDED };
export { LEGACY_HISTORY_FETCH_FAILED };

export { LEGACY_PROVISIONING_SUBMIT };
export { LEGACY_PROVISIONING_SUBMIT_REQUESTED };
export { LEGACY_PROVISIONING_SUBMIT_SUCCEEDED };
export { LEGACY_PROVISIONING_SUBMIT_FAILED };