import Axios from 'axios';

const ProvisionAPI = {
    historyFetch(userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport) {
        return Axios({
            method: 'get',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/history?applicationUserId=${userId}&fetchCiberseguridad=${fetchCiberseguridad}&fetchLegacy=${fetchLegacy}&fetchSmartsupport=${fetchSmartsupport}`,
        });
    },
};

export default ProvisionAPI;