import React from 'react';
import { Layout } from 'fractalia-design';
import { css } from '@emotion/core';

function WelcomePageLayout({ children, ...props }) {
  return (
    <Layout
      css={css`
         {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          padding: 20px 40px;
        }
      `}
      {...props}
    >
      {children}
    </Layout>
  );
}

export default WelcomePageLayout;
