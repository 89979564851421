/* eslint-disable react/jsx-props-no-spreading */

import {
  Button,
  Form,
  FractaliaLogo,
  Icons,
  Input,
  Spin,
  notification,
} from 'fractalia-design';

import IdentityAPI from '../api/IdentityAPI';
import React from 'react';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useIdentity } from 'fractalia-provisioner/identity';
import useLogin from '../hooks/useLogin';
import Oidc from 'oidc-client';
import { useHistory, useLocation } from 'react-router-dom';

const { UserOutlined, LockOutlined } = Icons;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;

  .form {
    padding: 35px 0px;
    width: 300px;
  }

  .login-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
`;

function LoginPage() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useIdentity();
  // const history = useHistory();
  // const location = useLocation();

  // const config = {
  //   authority: 'https://localhost:5001',
  //   client_id: 'js',
  //   redirect_uri: 'http://localhost:3000/identity/login',
  //   response_type: 'code',
  //   scope: 'openid',
  //   post_logout_redirect_uri: 'https://localhost:5003/index.html',
  // };

  // const mgr = new Oidc.UserManager(config);

  // useEffect(() => {
  //   const { search } = location();
  //   if (!search) {
  //     mgr.signinRedirect();
  //   }
  //   // // mgr.getUser().then(function (user) {
  //   // //   if (user) {
  //   // //     console.log('User logged in', user.profile);
  //   // //   } else {
  //   // //     console.log('User not logged in');
  //   // //   }
  //   // // });
  //   // // mgr.signinRedirect();
  //   // console.log('history', history);
  //   // console.log(mgr);
  // }, []);

  useEffect(() => {
    if (isAuthenticated && !!user) {
      dispatch(push('/'));
    }
  }, []);

  const {
    loginSubmit,
    loginSubmitRequested,
    loginSubmitSucceeded,
    loginSubmitFailed,
    loginSubmitFailedErrorMessage,
  } = useLogin();

  useEffect(() => {
    if (loginSubmitSucceeded) {
      onLoginSubmitSucceeded();
    }

    if (loginSubmitFailed) {
      onLoginSubmitFailed();
    }
  }, [loginSubmitSucceeded, loginSubmitFailed]);

  function onLoginSubmit(values) {
    const { username, password } = values;
    loginSubmit(username, password);
  }

  function onLoginSubmitSucceeded() {
    dispatch(push('/'));
  }

  function onLoginSubmitFailed() {
    notification.error({
      message: loginSubmitFailedErrorMessage,
      duration: 3,
      placement: 'bottomRight',
    });
  }

  function onFinish(values) {
    onLoginSubmit(values);
    console.log('Success:', values);
  }

  function onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  return (
    <Spin spinning={loginSubmitRequested}>
      <Wrapper>
        <LogoContainer>
          <FractaliaLogo height={50} classname="logo" />
        </LogoContainer>

        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form"
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese su nombre de usuario',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="login-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Por favor ingrese su contraseña' },
            ]}
          >
            <Input
              prefix={<LockOutlined className="login-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Iniciar Sesión
            </Button>
          </Form.Item>
        </Form>
      </Wrapper>
    </Spin>
  );
}

export default LoginPage;
