import { SET_CURRENT_MENU, SUBMISSION_HISTORY_FETCH, SUBMISSION_HISTORY_FETCH_FAILED, SUBMISSION_HISTORY_FETCH_REQUESTED, SUBMISSION_HISTORY_FETCH_SUCCEEDED } from './types';

export function setCurrentMenuAction(currentMenu) {
    return {
        type: SET_CURRENT_MENU,
        currentMenu,
    };
}

export function submissionHistoryFetchAction({ userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport }) {
    return {
        type: SUBMISSION_HISTORY_FETCH,
        userId,
        fetchCiberseguridad,
        fetchLegacy,
        fetchSmartsupport,
    };
}

export function submissionHistoryFetchRequestedAction() {
    return {
        type: SUBMISSION_HISTORY_FETCH_REQUESTED,
    };
}

export function submissionHistoryFetchSucceededAction(data) {
    return {
        type: SUBMISSION_HISTORY_FETCH_SUCCEEDED,
        data,
    };
}

export function submissionHistoryFetchFailedAction(error) {
    return {
        type: SUBMISSION_HISTORY_FETCH_FAILED,
        error,
    };
}