/* eslint-disable no-underscore-dangle */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const _state = (state) => state.legacy || initialState;

export const makeLegacyProvisioningSubmitRequestedSelector = () => createSelector(_state, (state) => state.legacyProvisioningSubmitRequested);
export const makeLegacyProvisioningSubmitSucceededSelector = () => createSelector(_state, (state) => state.legacyProvisioningSubmitSucceeded);
export const makeLegacyProvisioningSubmitFailedSelector = () => createSelector(_state, (state) => state.legacyProvisioningSubmitFailed);

export const makeLegacyHistoryFetchRequestedSelector = () => createSelector(_state, (state) => state.legacyHistoryFetchRequested);
export const makeLegacyHistoryFetchSucceededSelector = () => createSelector(_state, (state) => state.legacyHistoryFetchSucceeded);
export const makeLegacyHistoryFetchFailedSelector = () => createSelector(_state, (state) => state.legacyHistoryFetchFailed);
export const makeLegacyHistoryDataSelector = () => createSelector(_state, (state) => state.legacyHistoryData);