/* eslint-disable no-param-reassign */

import produce, { setAutoFreeze } from 'immer';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { LOCATION_CHANGE } from 'connected-react-router';
import menus from '../menus';

import { SET_CURRENT_MENU, SUBMISSION_HISTORY_FETCH, SUBMISSION_HISTORY_FETCH_FAILED, SUBMISSION_HISTORY_FETCH_REQUESTED, SUBMISSION_HISTORY_FETCH_SUCCEEDED } from './types';

const initialState = {
    currentMenu: 'home',
    submissionHistoryFetchRequested: false,
    submissionHistoryFetchSucceeded: false,
    submissionHistoryFetchFailed: false,
    submissionHistoryData: [],
};

const persistConfig = {
    key: 'app',
    storage: localForage,
    blacklist: ['currentMenu', 'submissionHistoryFetchRequested', 'submissionHistoryFetchSucceeded', 'submissionHistoryFetchFailed'],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SET_CURRENT_MENU:
            draft.currentMenu = action.currentMenu;
            break;

        case SUBMISSION_HISTORY_FETCH:
            draft.submissionHistoryData = [];
            draft.submissionHistoryFetchRequested = false;
            draft.submissionHistoryFetchSucceeded = false;
            draft.submissionHistoryFetchFailed = false;
            break;

        case SUBMISSION_HISTORY_FETCH_REQUESTED:
            draft.submissionHistoryData = [];
            draft.submissionHistoryFetchRequested = true;
            draft.submissionHistoryFetchSucceeded = false;
            draft.submissionHistoryFetchFailed = false;
            break;

        case SUBMISSION_HISTORY_FETCH_SUCCEEDED:
            draft.submissionHistoryData = action.data;
            draft.submissionHistoryFetchRequested = false;
            draft.submissionHistoryFetchSucceeded = true;
            draft.submissionHistoryFetchFailed = false;
            break;

        case SUBMISSION_HISTORY_FETCH_FAILED:
            draft.submissionHistoryData = [];
            draft.submissionHistoryFetchRequested = false;
            draft.submissionHistoryFetchFailed = true;
            draft.submissionHistoryFetchRequested = false;
            break;

        case LOCATION_CHANGE:
            draft.submissionHistoryData = [];
            draft.submissionHistoryFetchRequested = false;
            draft.submissionHistoryFetchFailed = false;
            draft.submissionHistoryFetchRequested = false;
            const { location } = action.payload;
            const { path: currentPath } = location;
            const menyKey = menus.find((menu) => menu.path === currentPath);
            draft.currentMenu = menyKey;
            break;
    }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);