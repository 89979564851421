import { push } from 'connected-react-router';
import moment from 'moment';
import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../store/actions';
import * as selectors from '../store/selectors';

const appContext = createContext();

// eslint-disable-next-line react/prop-types
export function AppProvider({ children }) {
  // eslint-disable-next-line no-use-before-define
  const app = useAppProvider();

  return (
    <appContext.Provider value={app}>
      {children}
    </appContext.Provider>
  );
}

export function useApp() {
  return useContext(appContext);
}

const {
  makeCurrentMenuSelector,
} = selectors;

function useAppProvider() {
  const dispatch = useDispatch();

  const {
    setCurrentMenuAction,
  } = actions;

  const {
    currentMenu,
  } = useSelector(
    createStructuredSelector({
      currentMenu: makeCurrentMenuSelector(),
    })
  );

  function onSetCurrentMenu(newCurrentMenu) {
    dispatch(setCurrentMenuAction(newCurrentMenu));
  }

  return {
    currentMenu,
    onSetCurrentMenu,
  };
}