import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import XLSX from 'xlsx';

import { ciberalarmaProvisioningSubmitAction } from '../store/actions';
import {
  makeCiberalarmaProvisioningSubmitFailedSelector,
  makeCiberalarmaProvisioningSubmitRequestedSelector,
  makeCiberalarmaProvisioningSubmitSucceededSelector,
} from '../store/selectors';

function useCiberalarma() {
  const dispatch = useDispatch();

  const {
    ciberalarmaProvisioningSubmitRequested,
    ciberalarmaProvisioningSubmitSucceeded,
    ciberalarmaProvisioningSubmitFailed,
  } = useSelector(
    createStructuredSelector({
      ciberalarmaProvisioningSubmitRequested: makeCiberalarmaProvisioningSubmitRequestedSelector(),
      ciberalarmaProvisioningSubmitSucceeded: makeCiberalarmaProvisioningSubmitSucceededSelector(),
      ciberalarmaProvisioningSubmitFailed: makeCiberalarmaProvisioningSubmitFailedSelector(),
    })
  );

  function ciberalarmaProvisioningSubmit(data, provisioningSourceFile) {
    dispatch(ciberalarmaProvisioningSubmitAction(data, provisioningSourceFile));
  }

  return {
    ciberalarmaProvisioningSubmit,
    ciberalarmaProvisioningSubmitRequested,
    ciberalarmaProvisioningSubmitSucceeded,
    ciberalarmaProvisioningSubmitFailed,
  };
}

export default useCiberalarma;
