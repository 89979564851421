import {
  makeLegacyProvisioningSubmitFailedSelector,
  makeLegacyProvisioningSubmitRequestedSelector,
  makeLegacyProvisioningSubmitSucceededSelector,
} from '../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { createStructuredSelector } from 'reselect';
import { legacyProvisioningSubmitAction } from '../store/actions';

function useLegacy() {
  const dispatch = useDispatch();

  const {
    legacyProvisioningSubmitRequested,
    legacyProvisioningSubmitSucceeded,
    legacyProvisioningSubmitFailed,
  } = useSelector(
    createStructuredSelector({
      legacyProvisioningSubmitRequested: makeLegacyProvisioningSubmitRequestedSelector(),
      legacyProvisioningSubmitSucceeded: makeLegacyProvisioningSubmitSucceededSelector(),
      legacyProvisioningSubmitFailed: makeLegacyProvisioningSubmitFailedSelector(),
    })
  );

  function legacyProvisioningSubmit(data, provisioningSourceFile) {
    dispatch(
      legacyProvisioningSubmitAction(data, provisioningSourceFile)
    );
  }
  return {
    legacyProvisioningSubmit,
    legacyProvisioningSubmitRequested,
    legacyProvisioningSubmitSucceeded,
    legacyProvisioningSubmitFailed,
  };
}

export default useLegacy;
