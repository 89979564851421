import CiberalarmaProvisionerPage from './pages/CiberalarmaPage';
import useCiberalarma from './hooks/useCiberalarma';

import * as actions from './store/actions';
import createReducer, { initialState } from './store/reducers';
import sagas from './store/sagas';
import * as selectors from './store/selectors';
import * as types from './store/types';

const store = {
    actions,
    createReducer,
    sagas,
    selectors,
    types,
};

export default { CiberalarmaProvisionerPage, useCiberalarma };
export { CiberalarmaProvisionerPage };
export { useCiberalarma };
export { store };