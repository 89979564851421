import React from 'react';
import { Table as TableBase } from 'fractalia-design';
import styled from 'styled-components';
import { colors } from 'fractalia-design';

const Table = styled(TableBase)`
  margin-top: 18px;
  max-width: 90vw;
  margin-bottom: 18px;
  .black-row {
    background-color: #f0f0f0;
  }
`;

function UsersTable({ children, ...rest }) {
  console.log(colors);
  return (
    <Table
      rowClassName={(record, index) =>
        (index + 1) % 2 === 0 ? 'black-row' : ''
      }
      pagination={{ position: ['topCenter'] }}
      {...rest}
    >
      {children}
    </Table>
  );
}

export default UsersTable;
