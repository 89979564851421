import Axios from 'axios';

const client = {
  submitProvisioning(transactions, sourceFile) {
    const form = new FormData();
    // form.append('transactions', transactions);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < transactions.length; i++) {
      if (transactions[i].customerRef) form.append(`smartsupportUserDtos[${i}].customerRef`, transactions[i].customerRef);
      if (transactions[i].login) form.append(`smartsupportUserDtos[${i}].login`, transactions[i].login);
      if (transactions[i].name) form.append(`smartsupportUserDtos[${i}].name`, transactions[i].name);
      if (transactions[i].surname) form.append(`smartsupportUserDtos[${i}].surname`, transactions[i].surname);
      if (transactions[i].email) form.append(`smartsupportUserDtos[${i}].email`, transactions[i].email);
      if (transactions[i].address) form.append(`smartsupportUserDtos[${i}].address`, transactions[i].address);
      if (transactions[i].city) form.append(`smartsupportUserDtos[${i}].city`, transactions[i].city);
      if (transactions[i].country) form.append(`smartsupportUserDtos[${i}].country`, transactions[i].country);
      if (transactions[i].phone) form.append(`smartsupportUserDtos[${i}].phone`, transactions[i].phone);
      if (transactions[i].action) form.append(`smartsupportUserDtos[${i}].action`, transactions[i].action);
      if (transactions[i].sourceRow) form.append(`smartsupportUserDtos[${i}].sourceRow`, transactions[i].sourceRow);
      if (transactions[i].projectCode) form.append(`smartsupportUserDtos[${i}].projectCode`, transactions[i].projectCode);
      if (transactions[i].userId) form.append(`smartsupportUserDtos[${i}].applicationUserId`, transactions[i].userId);
    }
    form.append('smartsupportUserFileDto', sourceFile);
    return Axios({
      method: 'post',
      // baseURL: 'https://localhost:5003',
      baseURL: process.env.REACT_APP_API_PROVISION_URL,
      url: `provision/smartsupport/users`,
      data: form,
      timeout: 200000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default client;
