import { all, call, put, takeLatest } from 'redux-saga/effects';

import ProvisionAPI from '../api/ProvisionAPI';
import { submissionHistoryFetchFailedAction, submissionHistoryFetchRequestedAction, submissionHistoryFetchSucceededAction } from './actions';

import { SUBMISSION_HISTORY_FETCH } from './types';

const historyFetchAsync = async(userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport) =>
    // eslint-disable-next-line no-return-await
    await ProvisionAPI.historyFetch(userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* historyFetchSaga(action) {
    try {
        // requested
        yield put(submissionHistoryFetchRequestedAction());
        const { userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport } = action;
        const data = yield call(historyFetchAsync, userId, fetchCiberseguridad, fetchLegacy, fetchSmartsupport);
        // succeeded
        yield put(submissionHistoryFetchSucceededAction(data));
    } catch (error) {
        // error
        yield put(submissionHistoryFetchFailedAction(error));
    }
}

export default function* rootSaga() {
    yield all([takeLatest(SUBMISSION_HISTORY_FETCH, historyFetchSaga)]);
}