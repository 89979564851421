/* eslint-disable react/prop-types */
/* eslint-disable spaced-comment */
/*
 * @Author: gvillena
 * @Date: 2020-06-26 02:38:27
 * @Last Modified by: gvillena
 * @Last Modified time: 2020-06-26 02:50:29
 */

import { Button, Icons, Space } from 'fractalia-design';

import React from 'react';
import styled from '@emotion/styled';

const { CheckOutlined, DeleteOutlined, InfoCircleOutlined } = Icons;

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: right;
`;

const ActionButton = styled(Button)`
  width: 110px;
`;

//========================================================================================
/*                                                                                      *
 *                                        Actions                                       *
 *                                                                                      */
//========================================================================================

function Actions({
  projectCodeList,
  projectCode,
  onProjectCodeChange,
  onSubmit,
  onReset,
  onShowInfo,
}) {
  return (
    <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Space css={{ maxWidth: '100%' }}>
        <ActionButton
          type="primary"
          onClick={onSubmit}
          icon={<CheckOutlined />}
        >
          Enviar
        </ActionButton>

        <ActionButton onClick={onReset} icon={<DeleteOutlined />}>
          Limpiar
        </ActionButton>
        <ActionButton onClick={onShowInfo} icon={<InfoCircleOutlined />}>
          Info
        </ActionButton>
      </Space>
    </div>
  );
}

export default Actions;

/* <ProjectCodeSelector
        options={projectCodeList}
        onChange={(value) => onProjectCodeChange(value)}
        value={projectCode}
      /> */
