import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    legacyProvisioningSubmitFailedAction,
    legacyProvisioningSubmitRequestedAction,
    legacyProvisioningSubmitSucceededdAction,
    legacyHistoryFetchFailedAction,
    legacyHistoryFetchRequestedAction,
    legacyHistoryFetchSucceededAction,
} from './actions';

import { LEGACY_PROVISIONING_SUBMIT, LEGACY_HISTORY_FETCH } from './types';
import LegacyAPI from '../api/LegacyAPI';

const provisioningSubmitAsync = async(data, provisioningSourceFile) =>
    // eslint-disable-next-line no-return-await
    await LegacyAPI.submitProvisioning(data, provisioningSourceFile)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

const historyFetchAsync = async(applicationUserId) =>
    // eslint-disable-next-line no-return-await
    await LegacyAPI.historyFetch(applicationUserId)
    .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* provisioningSubmitSaga(action) {
    try {
        const { data, provisioningSourceFile } = action;
        // requested
        yield put(legacyProvisioningSubmitRequestedAction());
        yield call(provisioningSubmitAsync, data, provisioningSourceFile);
        // succeeded
        yield put(legacyProvisioningSubmitSucceededdAction());
    } catch (error) {
        // error
        yield put(legacyProvisioningSubmitFailedAction(error));
    }
}

function* historyFetchSaga(action) {
    try {
        // requested
        yield put(legacyHistoryFetchRequestedAction());
        const { applicationUserId } = action;
        const data = yield call(historyFetchAsync, applicationUserId);
        // succeeded
        yield put(legacyHistoryFetchSucceededAction(data));
    } catch (error) {
        // error
        yield put(legacyHistoryFetchFailedAction(error));
    }
}

export default function* rootSaga() {
    yield all([takeLatest(LEGACY_PROVISIONING_SUBMIT, provisioningSubmitSaga), takeLatest(LEGACY_HISTORY_FETCH, historyFetchSaga)]);
}