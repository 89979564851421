import Axios from 'axios';
import moment from 'moment';

const client = {
    submitProvisioning(transactions, sourceFile) {
        const form = new FormData();
        // form.append('transactions', transactions);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].action) form.append(`legacyUserDtos[${i}].action`, transactions[i].action);
            if (transactions[i].address) form.append(`legacyUserDtos[${i}].address`, transactions[i].address);
            if (transactions[i].customerRef) form.append(`legacyUserDtos[${i}].customerRef`, transactions[i].customerRef);
            if (transactions[i].email) form.append(`legacyUserDtos[${i}].email`, transactions[i].email);
            if (transactions[i].license_login) form.append(`legacyUserDtos[${i}].license_login`, transactions[i].license_login);
            if (transactions[i].name) form.append(`legacyUserDtos[${i}].name`, transactions[i].name);
            if (transactions[i].personalID) form.append(`legacyUserDtos[${i}].personalId`, transactions[i].personalID);
            if (transactions[i].postalCode) form.append(`legacyUserDtos[${i}].postalCode`, transactions[i].postalCode);
            if (transactions[i].surname) form.append(`legacyUserDtos[${i}].surname`, transactions[i].surname);
            if (transactions[i].surname2) form.append(`legacyUserDtos[${i}].surname2`, transactions[i].surname2);
            if (transactions[i].phone) form.append(`legacyUserDtos[${i}].phone`, transactions[i].phone);
            if (transactions[i].city) form.append(`legacyUserDtos[${i}].city`, transactions[i].city);
            if (transactions[i].validUntil) {
                form.append(`legacyUserDtos[${i}].validUntil`, moment(transactions[i].validUntil).format('YYYY-MM-DD'));
            }
            if (transactions[i].sourceRow) form.append(`legacyUserDtos[${i}].sourceRow`, transactions[i].sourceRow);
            if (transactions[i].projectCode) form.append(`legacyUserDtos[${i}].projectCode`, transactions[i].projectCode);
            if (transactions[i].userId) form.append(`legacyUserDtos[${i}].applicationUserId`, transactions[i].userId);
        }
        form.append('legacyUserFileDto', sourceFile);

        return Axios({
            method: 'post',
            // baseURL: 'https://localhost:5003',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/legacy/users`,
            data: form,
            timeout: 30000,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    historyFetch(applicationUserId) {
        return Axios({
            method: 'get',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/legacy/history?applicationUserId=${applicationUserId}`,
        });
    },
};

export default client;