import { createSelector } from 'reselect';
import { initialState } from './reducers';

const identityState = (state) => state.identity || initialState;

const makeUserLoginRequestedSelector = () => createSelector(identityState, (state) => state.userLoginRequested);

const makeUserLoginSucceededSelector = () => createSelector(identityState, (state) => state.userLoginSucceeded);

const makeUserLoginFailedSelector = () => createSelector(identityState, (state) => state.userLoginFailed);

const makeUserLoginFailedErrorSelector = () => createSelector(identityState, (state) => state.userLoginFailedError);

const makeUserLoginFailedErrorMessageSelector = () => createSelector(identityState, (state) => state.userLoginFailedErrorMessage);

const makeUserSelector = () => createSelector(identityState, (state) => state.user);

const makeProjectCodesSelector = () => createSelector(identityState, (state) => state.projectCodes);

const makeTokenSelector = () => createSelector(identityState, (state) => state.token);

const makeIsAuthenticatedSelector = () => createSelector(identityState, (state) => state.isAuthenticated);

export default identityState;

export {
  makeUserLoginRequestedSelector,
  makeUserLoginSucceededSelector,
  makeUserLoginFailedSelector,
  makeUserLoginFailedErrorSelector,
  makeUserLoginFailedErrorMessageSelector,
  makeUserSelector,
  makeProjectCodesSelector,
  makeTokenSelector,
  makeIsAuthenticatedSelector,
};
