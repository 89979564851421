import { all, fork } from 'redux-saga/effects';

import app from '../app/store/sagas';
import ciberseguridad from '../modules/ciberseguridad/store/sagas';
import ciberalarma from '../modules/ciberalarma/store/sagas';
import identity from '../modules/identity/store/sagas';
import legacy from '../modules/legacy/store/sagas';
import smartsupport from '../modules/smartsupport/store/sagas';
import shared from '../modules/shared/store/sagas';

export default function* rootSaga() {
    yield all([fork(app), fork(smartsupport), fork(identity), fork(legacy), fork(ciberseguridad), fork(shared), fork(ciberalarma)]);
}