/* eslint-disable react/prop-types */

import React from 'react';
import logoSVG from './logo.svg';

function FractaliaLogo({ height = 24 }) {
  return <img src={logoSVG} height={height} alt="Grupo Fractalia" />;
}

export default FractaliaLogo;
