/* eslint-disable spaced-comment */

import React from 'react'

//========================================================================================
/*                                                                                      *
 *                                    useRowValidator                                   *
 *                                                                                      */
//========================================================================================

function useRowValidator(columns) {

  function checkInvalidRows(rows) {
    const errors = [];
    const required = columns
      .filter((col) => col.required)
      .map((col) => col.key);
    rows.forEach((r, index) => {
      // required validation
      Object.entries(r).forEach(([key, value]) => {
        if (required.includes(key) && value === null) {
          const { header } = columns.find((col) => col.key === key);
          const type = 'required';
          errors.push({ header, type, index });
        }
      });
    });
    return errors;
  }

  function reduceInvalidRows(rows) {
    return rows.reduce((acc, row) => {
      const rowIndex = `fila ${row.index + 1}`;
      if (!acc[rowIndex]) {
        acc[rowIndex] = [];
      }
      acc[rowIndex].push(row);
      return acc;
    }, []);
  }

  return { checkInvalidRows, reduceInvalidRows }
}

export default useRowValidator
