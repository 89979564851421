/* eslint-disable no-underscore-dangle */

import { createSelector } from 'reselect';

import { initialState } from './reducers';

const _state = (state) => state.ciberalarma || initialState;

export const makeCiberalarmaProvisioningSubmitRequestedSelector = () => createSelector(_state, (state) => state.ciberalarmaProvisioningSubmitRequested);
export const makeCiberalarmaProvisioningSubmitSucceededSelector = () => createSelector(_state, (state) => state.ciberalarmaProvisioningSubmitSucceeded);
export const makeCiberalarmaProvisioningSubmitFailedSelector = () => createSelector(_state, (state) => state.ciberalarmaProvisioningSubmitFailed);