import React from 'react';
import { css } from '@emotion/core';
import UserProjectsPermissionTable from './UserProjectPermissionTable';
import { Tag } from 'fractalia-design';
import arraySort from 'array-sort';

function UserProjectsPermissionWidget({ headerText, data }) {
  const styles = css`
    .header {
      font-size: 1.1rem;
      color: #1890ff;
    }
  `;

  const pagination = {
    pageSize: 5,
  };

  function getPartnerColor(partnerName) {
    switch (partnerName) {
      case 'ciberalarma':
        return 'geekblue';
        break;

      default:
        break;
    }
  }

  function sortProjects(projects) {
    if (projects.lenght === 0) {
      return projects;
    }
    return arraySort(projects, ['partner', 'description']);
  }

  return (
    <div css={styles}>
      <div className="header">{headerText}</div>
      <UserProjectsPermissionTable data={sortProjects(data)} />
    </div>
  );
}

export default UserProjectsPermissionWidget;
