import React from 'react';
import { Row as RowBase, Col as ColBase, Steps, colors, Button } from 'fractalia-design';
import styled from '@emotion/styled';

const { Step } = Steps;

const { red } = colors;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 780px;
  align-self: center;
  margin-top: 30px;

  .steps {
    margin-top: 40px;
    padding-left: 30px;
    margin-bottom: 15px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-left: auto;
`;

const Row = styled(RowBase)`
  width: 80%;
`;

const Col = styled(ColBase)`
  padding: 0px 40px;
`;

const H1 = styled.h1`
  font-size: 1.5rem;
  color: #1890ff;
`;

const H4 = styled.h4`
  font-size: 0.9rem;
  color: ${red[5]};
`;

const P = styled.p``;

const Text = styled.div`
  text-align: justify;
  text-justfy: inter-word;
  font-size: 0.9rem;
`;

const StepsWrapper = styled.div`
  width: 100%;
  padding: 30px 80px;
`;

function GettingStarted({ onStartClick, title, text, templateFile, onShowHistory }) {
  return (
    <Wrapper>
      <H1>{title}</H1>
      <Text>{`${text}, en tres simples pasos:`}</Text>

      <Steps direction="vertical" size="small" className="steps">
        <Step title={<div>Subida del fichero *.xlsx que contiene su informacion</div>} status="finish" />
        <Step title={<div>Verifique si los registros y su informacion son correctos</div>} status="finish" />
        <Step title={<div>Confirme el envío y espere la respuesta del servidor</div>} status="finish" />
      </Steps>

      <H4>Notas importantes:</H4>
      <P>
        <ul>
          <li>Solo se permiten subir archivos con extension XLSX (*.xlsx).</li>
          <li>
            Descargue el{' '}
            <Button css={{ padding: '0px' }} type="link" href={templateFile} target="_blank">
              fichero con datos y formato de ejemplo
            </Button>{' '}
            para usarlo como plantilla con el fin del provisionar nuevos usuarios/licencias con las columnas adecuadas.
          </li>
        </ul>
      </P>
      <ButtonContainer>
        <Button type="primary" size="large" onClick={onStartClick}>
          Provisionar un archivo excel (.xlsx)
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default GettingStarted;
