/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { LOCATION_CHANGE } from 'connected-react-router';
import produce, { setAutoFreeze } from 'immer';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import { CIBERALARMA_PROVISIONING_SUBMIT, CIBERALARMA_PROVISIONING_SUBMIT_FAILED, CIBERALARMA_PROVISIONING_SUBMIT_REQUESTED, CIBERALARMA_PROVISIONING_SUBMIT_SUCCEEDED } from './types';

const initialState = {
    ciberalarmaProvisioningSubmitRequested: false,
    ciberalarmaProvisioningSubmitSucceeded: false,
    ciberalarmaProvisioningSubmitFailed: false,
};

const persistConfig = {
    key: 'ciberalarma',
    storage: localForage,
    blacklist: ['ciberalarmaProvisioningSubmitRequested', 'ciberalarmaProvisioningSubmitSucceeded', 'ciberalarmaProvisioningSubmitFailed'],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
    switch (action.type) {
        case CIBERALARMA_PROVISIONING_SUBMIT:
            draft.ciberalarmaProvisioningSubmitRequested = false;
            draft.ciberalarmaProvisioningSubmitSucceeded = false;
            draft.ciberalarmaProvisioningSubmitFailed = false;
            break;

        case CIBERALARMA_PROVISIONING_SUBMIT_REQUESTED:
            draft.ciberalarmaProvisioningSubmitRequested = true;
            draft.ciberalarmaProvisioningSubmitSucceeded = false;
            draft.ciberalarmaProvisioningSubmitFailed = false;
            break;

        case CIBERALARMA_PROVISIONING_SUBMIT_SUCCEEDED:
            draft.ciberalarmaProvisioningSubmitRequested = false;
            draft.ciberalarmaProvisioningSubmitSucceeded = true;
            draft.ciberalarmaProvisioningSubmitFailed = false;
            break;

        case CIBERALARMA_PROVISIONING_SUBMIT_FAILED:
            draft.ciberalarmaProvisioningSubmitRequested = false;
            draft.ciberalarmaProvisioningSubmitFailed = true;
            draft.ciberalarmaProvisioningSubmitRequested = false;
            break;

        case LOCATION_CHANGE:
            draft.ciberalarmaProvisioningSubmitRequested = false;
            draft.ciberalarmaProvisioningSubmitSucceeded = false;
            draft.ciberalarmaProvisioningSubmitFailed = false;
            break;
    }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);