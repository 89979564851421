import Axios from 'axios';

const CiberalarmaAPI = {
    submitProvisioning(transactions, sourceFile) {
        const form = new FormData();
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].customerRef) form.append(`ciberalarmaUserDtos[${i}].customerRef`, transactions[i].customerRef);
            if (transactions[i].name) form.append(`ciberalarmaUserDtos[${i}].name`, transactions[i].name);
            if (transactions[i].surname1) form.append(`ciberalarmaUserDtos[${i}].surname1`, transactions[i].surname1);
            if (transactions[i].surname2) form.append(`ciberalarmaUserDtos[${i}].surname2`, transactions[i].surname2);
            if (transactions[i].email) form.append(`ciberalarmaUserDtos[${i}].email`, transactions[i].email);
            if (transactions[i].phone) form.append(`ciberalarmaUserDtos[${i}].phone`, transactions[i].phone);
            if (transactions[i].action) form.append(`ciberalarmaUserDtos[${i}].action`, transactions[i].action);
            if (transactions[i].projectCode) form.append(`ciberalarmaUserDtos[${i}].projectCode`, transactions[i].projectCode);
            if (transactions[i].userId) form.append(`ciberalarmaUserDtos[${i}].applicationUserId`, transactions[i].userId);
            if (transactions[i].sourceRow) form.append(`ciberalarmaUserDtos[${i}].sourceRow`, transactions[i].sourceRow);
        }
        form.append('ciberalarmaUserFileDto', sourceFile);

        return Axios({
            method: 'post',
            // baseURL: 'https://localhost:5003',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/ciberalarma/users`,
            data: form,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    historyFetch(applicationUserId) {
        return Axios({
            method: 'get',
            baseURL: process.env.REACT_APP_API_PROVISION_URL,
            url: `provision/ciberalarma/history?applicationUserId=${applicationUserId}`,
        });
    },
};

export default CiberalarmaAPI;