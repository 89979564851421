/* eslint-disable import/prefer-default-export */

import {
    LEGACY_PROVISIONING_SUBMIT,
    LEGACY_PROVISIONING_SUBMIT_FAILED,
    LEGACY_PROVISIONING_SUBMIT_REQUESTED,
    LEGACY_PROVISIONING_SUBMIT_SUCCEEDED,
    LEGACY_HISTORY_FETCH,
    LEGACY_HISTORY_FETCH_FAILED,
    LEGACY_HISTORY_FETCH_REQUESTED,
    LEGACY_HISTORY_FETCH_SUCCEEDED,
} from './types';

export function legacyProvisioningSubmitAction(data, provisioningSourceFile) {
    return {
        type: LEGACY_PROVISIONING_SUBMIT,
        data,
        provisioningSourceFile
    };
}

export function legacyProvisioningSubmitRequestedAction() {
    return {
        type: LEGACY_PROVISIONING_SUBMIT_REQUESTED,
    };
}

export function legacyProvisioningSubmitSucceededdAction() {
    return {
        type: LEGACY_PROVISIONING_SUBMIT_SUCCEEDED,
    };
}

export function legacyProvisioningSubmitFailedAction(error) {
    return {
        type: LEGACY_PROVISIONING_SUBMIT_FAILED,
        error,
    };
}

export function legacyHistoryFetchAction(applicationUserId) {
    return {
        type: LEGACY_HISTORY_FETCH,
        applicationUserId,
    };
}

export function legacyHistoryFetchRequestedAction() {
    return {
        type: LEGACY_HISTORY_FETCH_REQUESTED,
    };
}

export function legacyHistoryFetchSucceededAction(data) {
    return {
        type: LEGACY_HISTORY_FETCH_SUCCEEDED,
        data,
    };
}

export function legacyHistoryFetchFailedAction(error) {
    return {
        type: LEGACY_HISTORY_FETCH_FAILED,
        error,
    };
}