/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { LOCATION_CHANGE } from 'connected-react-router';
import {
    LEGACY_PROVISIONING_SUBMIT,
    LEGACY_PROVISIONING_SUBMIT_FAILED,
    LEGACY_PROVISIONING_SUBMIT_REQUESTED,
    LEGACY_PROVISIONING_SUBMIT_SUCCEEDED,
    LEGACY_HISTORY_FETCH,
    LEGACY_HISTORY_FETCH_FAILED,
    LEGACY_HISTORY_FETCH_REQUESTED,
    LEGACY_HISTORY_FETCH_SUCCEEDED,
} from './types';
import produce, { setAutoFreeze } from 'immer';

import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

const initialState = {
    legacyProvisioningSubmitRequested: false,
    legacyProvisioningSubmitSucceeded: false,
    legacyProvisioningSubmitFailed: false,
    legacyHistoryFetchRequested: false,
    legacyHistoryFetchSucceeded: false,
    legacyHistoryFetchFailed: false,
    legacyHistoryData: [],
};

const persistConfig = {
    key: 'legacy',
    storage: localForage,
    blacklist: ['legacyProvisioningSubmitRequested', 'legacyProvisioningSubmitSucceeded', 'legacyProvisioningSubmitFailed', 'legacyHistoryFetchRequested',
        'legacyHistoryFetchSucceeded',
        'legacyHistoryFetchFailed',
        'legacyHistoryData',
    ],
};

setAutoFreeze(false);

const reducer = produce((draft, action) => {
    switch (action.type) {
        case LEGACY_PROVISIONING_SUBMIT:
            draft.legacyProvisioningSubmitRequested = false;
            draft.legacyProvisioningSubmitSucceeded = false;
            draft.legacyProvisioningSubmitFailed = false;
            break;

        case LEGACY_PROVISIONING_SUBMIT_REQUESTED:
            draft.legacyProvisioningSubmitRequested = true;
            draft.legacyProvisioningSubmitSucceeded = false;
            draft.legacyProvisioningSubmitFailed = false;
            break;

        case LEGACY_PROVISIONING_SUBMIT_SUCCEEDED:
            draft.legacyProvisioningSubmitRequested = false;
            draft.legacyProvisioningSubmitSucceeded = true;
            draft.legacyProvisioningSubmitFailed = false;
            break;

        case LEGACY_PROVISIONING_SUBMIT_FAILED:
            draft.legacyProvisioningSubmitRequested = false;
            draft.legacyProvisioningSubmitFailed = true;
            draft.legacyProvisioningSubmitRequested = false;
            break;

        case LEGACY_HISTORY_FETCH:
            draft.legacyHistoryFetchRequested = false;
            draft.legacyHistoryFetchSucceeded = false;
            draft.legacyHistoryFetchFailed = false;
            break;

        case LEGACY_HISTORY_FETCH_REQUESTED:
            draft.legacyHistoryFetchRequested = true;
            draft.legacyHistoryFetchSucceeded = false;
            draft.legacyHistoryFetchFailed = false;
            break;

        case LEGACY_HISTORY_FETCH_SUCCEEDED:
            draft.legacyHistoryData = action.data;
            draft.legacyHistoryFetchRequested = false;
            draft.legacyHistoryFetchSucceeded = true;
            draft.legacyHistoryFetchFailed = false;
            break;

        case LEGACY_HISTORY_FETCH_FAILED:
            draft.legacyHistoryFetchRequested = false;
            draft.legacyHistoryFetchFailed = true;
            draft.legacyHistoryFetchRequested = false;
            break;

        case LOCATION_CHANGE:
            draft.legacyProvisioningSubmitRequested = false;
            draft.legacyProvisioningSubmitSucceeded = false;
            draft.legacyProvisioningSubmitFailed = false;
            draft.legacyHistoryFetchRequested = false;
            draft.legacyHistoryFetchSucceeded = false;
            draft.legacyHistoryFetchFailed = false;
            break;
    }
}, initialState);

export { initialState };
export default persistReducer(persistConfig, reducer);