import withReduxEnhancer from 'addon-redux/enhancer';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import history from '../utils/history';
import createReducer from './reducers';
import rootSaga from './sagas';

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
  const createMiddlewareEnhancer = () => {
    const middleware = [];
    middleware.push(sagaMiddleware);
    middleware.push(routeMiddleware);
    if (process.env.NODE_ENV !== 'production') {
      middleware.push(loggerMiddleware);
    }

    return applyMiddleware(...middleware);
  };

  const createEnhancer = () => {
    const enhancers = [];
    enhancers.push(createMiddlewareEnhancer());
    if (process.env.NODE_ENV !== 'production') {
      enhancers.push(withReduxEnhancer);
    }
    return compose(...enhancers);
  };

  const store = createStore(createReducer(), initialState, composeEnhancers(createEnhancer()));
  console.log(store);

  sagaMiddleware.run(rootSaga);

  const persistorConfig = {
    key: 'provisioning-webapp',
  };

  const persistor = persistStore(store, persistorConfig);
  return { store, persistor };
}
