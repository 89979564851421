/* eslint-disable import/prefer-default-export */

import { CIBERALARMA_PROVISIONING_SUBMIT, CIBERALARMA_PROVISIONING_SUBMIT_FAILED, CIBERALARMA_PROVISIONING_SUBMIT_REQUESTED, CIBERALARMA_PROVISIONING_SUBMIT_SUCCEEDED } from './types';

export function ciberalarmaProvisioningSubmitAction(data, provisioningSourceFile) {
    return {
        type: CIBERALARMA_PROVISIONING_SUBMIT,
        data,
        provisioningSourceFile,
    };
}

export function ciberalarmaProvisioningSubmitRequestedAction() {
    return {
        type: CIBERALARMA_PROVISIONING_SUBMIT_REQUESTED,
    };
}

export function ciberalarmaProvisioningSubmitSucceededAction() {
    return {
        type: CIBERALARMA_PROVISIONING_SUBMIT_SUCCEEDED,
    };
}

export function ciberalarmaProvisioningSubmitFailedAction(error) {
    return {
        type: CIBERALARMA_PROVISIONING_SUBMIT_FAILED,
        error,
    };
}