/* eslint-disable no-param-reassign */

import ciberseguridadReducer, { initialState as ciberseguridadInitialState } from '../modules/ciberseguridad/store/reducer';
import ciberalarmaReducer, { initialState as ciberalarmaInitialState } from '../modules/ciberalarma/store/reducers';
import appReducers, { initialState as appInitialState } from '../app/store/reducers';
import globalReducer, { initialState as globalInitialState } from '../modules/global/store/reducers';
import identityReducer, { initialState as identityInitialState } from '../modules/identity/store/reducers';
import legacyReducer, { initialState as legacyInitialState } from '../modules/legacy/store/reducer';
import smartSupportReducer, { initialState as smartSupportInitialState } from '../modules/smartsupport/store/reducer';
import sharedReducer, { initialState as sharedInitialState } from '../modules/shared/store/reducers';

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../utils/history';

// reducers
export const initialState = {
    app: appInitialState,
    global: globalInitialState,
    ciberseguridad: ciberseguridadInitialState,
    ciberalarma: ciberalarmaInitialState,
    smartSupport: smartSupportInitialState,
    legacy: legacyInitialState,
    identity: identityInitialState,
    shared: sharedInitialState,
};

// export default function createReducer() {
//   const rootReducer = combineReducers({
//     appUI: appUIReducers,
//   });
//   return rootReducer;
// }

export default function createReducer() {
    const rootReducer = combineReducers({
        app: appReducers,
        global: globalReducer,
        ciberseguridad: ciberseguridadReducer,
        ciberalarma: ciberalarmaReducer,
        smartSupport: smartSupportReducer,
        identity: identityReducer,
        legacy: legacyReducer,
        shared: sharedReducer,
        router: connectRouter(history),
    });
    return rootReducer;
}