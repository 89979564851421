/* eslint-disable import/prefer-default-export */

import {
    CIBERSEGURIDAD_PROVISIONING_SUBMIT,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED,
    CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED,
    CIBERSEGURIDAD_HISTORY_FETCH,
    CIBERSEGURIDAD_HISTORY_FETCH_FAILED,
    CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED,
    CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED,
} from './types';

export function ciberseguridadProvisioningSubmitAction(data, provisioningSourceFile) {
    return {
        type: CIBERSEGURIDAD_PROVISIONING_SUBMIT,
        data,
        provisioningSourceFile,
    };
}

export function ciberseguridadProvisioningSubmitRequestedAction() {
    return {
        type: CIBERSEGURIDAD_PROVISIONING_SUBMIT_REQUESTED,
    };
}

export function ciberseguridadProvisioningSubmitSucceededAction() {
    return {
        type: CIBERSEGURIDAD_PROVISIONING_SUBMIT_SUCCEEDED,
    };
}

export function ciberseguridadProvisioningSubmitFailedAction(error) {
    return {
        type: CIBERSEGURIDAD_PROVISIONING_SUBMIT_FAILED,
        error,
    };
}

export function ciberseguridadHistoryFetchAction(applicationUserId) {
    return {
        type: CIBERSEGURIDAD_HISTORY_FETCH,
        applicationUserId,
    };
}

export function ciberseguridadHistoryFetchRequestedAction() {
    return {
        type: CIBERSEGURIDAD_HISTORY_FETCH_REQUESTED,
    };
}

export function ciberseguridadHistoryFetchSucceededAction(data) {
    return {
        type: CIBERSEGURIDAD_HISTORY_FETCH_SUCCEEDED,
        data,
    };
}

export function ciberseguridadHistoryFetchFailedAction(error) {
    return {
        type: CIBERSEGURIDAD_HISTORY_FETCH_FAILED,
        error,
    };
}