/* eslint-disable no-return-await */

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { userLoginFailedAction, userLoginRequestedAction, userLoginSucceededAction } from './actions';

import IdentityAPI from '../api/IdentityAPI';
import { USER_LOGIN } from './types';

const authenticateAsync = async(username, password) =>
    await IdentityAPI.authenticate(username, password)
    .then((response) => response.json())
    .then((data) => data)
    // .then((response) => response.data)
    .catch((error) => {
        throw error;
    });

function* userLoginSaga(action) {
    const { username, password } = action;
    try {
        // request
        yield put(userLoginRequestedAction());
        const data = yield call(authenticateAsync, username, password);
        if (isString(data)) {
            yield put(userLoginFailedAction(data));
        } else {
            const user = { userId: data.userId, username: data.username, fullname: data.fullname, projects: data.projects };
            yield put(userLoginSucceededAction(user));
        }
    } catch (error) {
        // failed
        // console.log(error.response);
        let errorMessage = 'Error, por favor contacte con el administrador del sistema.';
        // if ((error.response.status = 401)) {
        //     errorMessage = error.response.data;
        // }
        yield put(userLoginFailedAction(errorMessage));
    }
}

function isString(obj) {
    return Object.prototype.toString.call(obj) === '[object String]';
}

export default function* loginSaga() {
    yield all([takeLatest(USER_LOGIN, userLoginSaga)]);
}