const columns = [{
        key: 'customerRef',
        header: 'CustomerRef',
        required: false,
    },
    {
        key: 'validUntil',
        header: 'ValidUntil',
        required: false,
    },
    {
        key: 'license_login',
        header: 'License or Login',
        required: true,
        width: 220,
        ellipsis: true,
    },
    {
        key: 'email',
        header: 'Email',
        required: true,
        width: 220,
        ellipsis: true,
    },
    {
        key: 'name',
        header: 'Name',
        required: true,
        width: 160,
        ellipsis: true,
    },
    {
        key: 'surname',
        header: 'Surname',
        required: false,
        width: 160,
        ellipsis: true,
    },
    {
        key: 'surname2',
        header: 'Surname2',
        required: false,
        width: 160,
        ellipsis: true,
    },
    {
        key: 'personalID',
        header: 'PersonalID',
        required: false,
        width: 120,
        ellipsis: true,
    },
    {
        key: 'address',
        header: 'Address',
        required: false,
        width: 200,
        ellipsis: true,
    },
    {
        key: 'postalCode',
        header: 'PostalCode',
        required: false,
        width: 120,
        ellipsis: true,
    },
    {
        key: 'city',
        header: 'City',
        required: false,
        width: 120,
        ellipsis: true,
    },
    {
        key: 'phone',
        header: 'Phone',
        required: false,
        width: 120,
        ellipsis: true,
    },
    {
        key: 'action',
        header: 'Action',
        required: true,
    },
];

export default columns;