import {
  makeProjectCodeGetAllSubmitFailedSelector,
  makeProjectCodeGetAllSubmitRequestedSelector,
  makeProjectCodeGetAllSubmitSucceededSelector,
  makeProjectCodeListSelector,

} from '../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { createStructuredSelector } from 'reselect';
import { projectCodeGetAllSubmitAction } from '../store/actions';

function useProjectCodeValidator() {
  const dispatch = useDispatch();

  const {
    projectCodeList,
    projectCodeGetAllSubmitRequested,
    projectCodeGetAllSubmitSucceeded,
    projectCodeGetAllSubmitFailed,
  } = useSelector(
    createStructuredSelector({
      projectCodeList: makeProjectCodeListSelector(),
      projectCodeGetAllSubmitRequested: makeProjectCodeGetAllSubmitRequestedSelector(),
      projectCodeGetAllSubmitSucceeded: makeProjectCodeGetAllSubmitSucceededSelector(),
      projectCodeGetAllSubmitFailed: makeProjectCodeGetAllSubmitFailedSelector(),
    })
  );

  function projectCodeGetAllSubmit() {
    dispatch(
      projectCodeGetAllSubmitAction()
    );
  }
  return {
    projectCodeList,
    projectCodeGetAllSubmit,
    projectCodeGetAllSubmitRequested,
    projectCodeGetAllSubmitSucceeded,
    projectCodeGetAllSubmitFailed,
  };
}

export default useProjectCodeValidator
